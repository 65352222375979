import React from "react";
import {Label, FormFeedback} from 'reactstrap'
// @ts-ignore
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface Props {
    value: Date|any,
    onChange: Function|any,
    error?: string|any,
    placeholder?: string|any,
    label?: string|any,

}

const QDate: React.FC<Props> = ({
    label,
    value,
    onChange,
    placeholder = '',
    error = '',
}) => {

    return (
        <div className="mb-3">
            { label ? (
                <Label>
                    { label }
                </Label>
            ) : null }
            <DatePicker
                placeholder={placeholder}
                className={'form-control'}
                selected={value || ''}
                onChange={(date: any) => {
                    onChange(date)
                }}
            />
            { error ? (
                <FormFeedback>
                    { error }
                </FormFeedback>
            ) : null }
        </div>
    )
}

export default QDate;