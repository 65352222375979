import {ServiceInterface} from "./ServiceShipmentInterface";

export interface LogInterface {

    _id: number,
    status: number,
    message: string,
    ref_id: string,
    type: string,
    data: string | any,
    updated_at: string,
    created_at: string,
    user_id: null
}

export const structLog: LogInterface = {
    _id: 0,
    created_at: "",
    data: "",
    message: "",
    ref_id: "",
    status: 0,
    type: "",
    updated_at: "",
    user_id: null
}
