import React, {useEffect, useState} from "react";
import QTextBox from "../../../../common/components/QTextBox";
import QSelect from "../../../../common/components/QSelect";
import UserApi from "../../../../common/api/functions/User";
import SetupApi from "../../../../common/api/functions/Setup";
import {ResponseInterface} from "../../../../interfaces/ResponseInterface";
import {
    AccountInterface,
    JournalInterface,
    LayoutInterface,
    PaymentTermInterface
} from "../../../../interfaces/economic/CommonInterface";
import {SelectOptionInterface} from "../../../../interfaces/SelectOptionInterface";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../app/store";
import {setLoading} from "../../../../app/rootReducer";

interface Props {
    formik: any
}

const Payout: React.FC<Props> = ({formik}) => {
    const {loading} = useSelector((state:RootState) => state.rootReducer);
    const dispatch = useDispatch();
    const [cashbooks,setCashbooks] = useState<JournalInterface[]>([]);
    const [accounts,setAccounts] = useState<AccountInterface[]>([]);

    useEffect(()=>{
        if(loading){
            SetupApi.getDataSetupPayout().then(async (response: ResponseInterface | any)=>{
                let {cashbooks,accounts} = response?.data?.data;
                setCashbooks(cashbooks);
                setAccounts(accounts);
                dispatch(setLoading(false));
            })

        }
    },[loading]);


    // console.log(formik.errors);
    return(
        <React.Fragment>
            <QSelect
                label={'Cashbook'}
                value={formik.values.economic_payout_cashbook_number}
                onChange={(option:SelectOptionInterface) => {
                    formik.setFieldValue('economic_payout_cashbook_number',option.value);
                }}
                options={cashbooks.map((a:JournalInterface)=>({label:a.name,value:a.journalNumber}))}
                error={formik.errors?.economic_payout_cashbook_number}
            />
            <QSelect
                label={'Bank account'}
                value={formik.values.economic_payout_bank_account_number}
                onChange={(option:SelectOptionInterface) => {
                    formik.setFieldValue('economic_payout_bank_account_number',option.value);
                }}
                options={accounts.map((a:AccountInterface)=>({label:a.accountNumber + ' - ' + a.name,value:a.accountNumber}))}
                error={formik.errors?.economic_payout_bank_account_number}
            />
            <QSelect
                label={'Fee account'}
                value={formik.values.economic_payout_fee_account_number}
                onChange={(option:SelectOptionInterface) => {
                    formik.setFieldValue('economic_payout_fee_account_number',option.value);
                }}
                options={accounts.map((a:AccountInterface)=>({label:a.accountNumber + ' - ' + a.name,value:a.accountNumber}))}
                error={formik.errors?.economic_payout_fee_account_number}
            />
            <QSelect
                label={'Difference currency account'}
                value={formik.values.economic_payout_difference_currency_account_number}
                onChange={(option:SelectOptionInterface) => {
                    formik.setFieldValue('economic_payout_difference_currency_account_number',option.value);
                }}
                options={accounts.map((a:AccountInterface)=>({label:a.accountNumber + ' - ' + a.name,value:a.accountNumber}))}
                error={formik.errors?.economic_payout_difference_currency_account_number}
            />

        </React.Fragment>
    )
}

export default Payout;
