import React, {useEffect, useState} from "react";
import {ResponseInterface} from "../../../interfaces/ResponseInterface";
import {config} from "../../../config";
import {PaginationInterface, structPagination} from "../../../interfaces/PaginationInterface";
import QPagination from "../../../common/components/QPaging";
import QLoading from "../../../common/components/QLoading";
import {Link} from "react-router-dom";
import CustomerApi from "../../../common/api/functions/Customer";
import {CustomerInterface} from "../../../interfaces/private/CustomerInterface";
import BodyDeliveryComponent from "../Order/components/BodyDelivevyComponent";
import QModal from "../../../common/components/QModal";
import BodyCustomerDetailComponent from "./components/BodyCustomerDetailComponent";
import {UseMainLayout} from "../../../context/LayoutContext";
import QSearch from "../../../common/components/Genneral/QSearch";
import ProductIcon from "../../../assets/images/products/image_15.png";
import {useTranslation} from "react-i18next";
export function Customer () {
  const { t } = useTranslation();
  const [customers, setCustomer] = useState <Array<CustomerInterface>>([]);
    const [pagination, setPagination] = useState <PaginationInterface>({...structPagination})
    const [loading, setLoading] = useState(true);
    const [clickCustomerItem, setClickCustomerItem] = useState(0);
    UseMainLayout();
    useEffect(  () => {
        if(loading){
            loadCustomer().then( async () => {
                await setLoading(false);
            });
        }
    }, [loading])

    const loadCustomer = async () => {
        try {
            await CustomerApi.getAll(pagination).then( async (response: ResponseInterface|any) => {
                if(response?.status === config.STATUS_CODE.OK){
                    response = response?.data?.data;
                    await setCustomer(
                        response?.data || []
                    )

                    await setPagination({
                        ...pagination,
                        page: response.current_page,
                        total: response.total,
                        per_page: response.per_page
                    })
                }
            })

        }catch (err){}
        if(loading){
            await setLoading(false);
        }
    }
    return (
        <React.Fragment>
            <BodyCustomerDetailComponent
                open={clickCustomerItem}
                setOpen={setClickCustomerItem}
            />
            <div className="col-md-12 relative">
                <div className="row ">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <div className="page-title-name">{t("customer.customers")}</div>
                            <div className="page-title-right">
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="mb-3">
                            <div className="card-header align-items-center d-flex">
                                <div className="mb-0 flex-grow-1">
                                    <ul className="nav nav-tabs nav-tabs-custom nav-success bd-none" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link All py-3 active pl-0" data-bs-toggle="tab" id="All" href="#All"
                                               role="tab" aria-selected="false">{t("common.all")}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <QSearch
                                    onChange={ async (value: string) => {
                                        await setPagination({
                                            ...pagination,
                                            search: value,
                                        })
                                        await setLoading(true);
                                    }}
                                />
                            </div>
                            <QLoading loading={loading} />
                            <table className="table align-middle table-nowrap mb-0 relative" id="customerTable">
                                <thead className="table-light">
                                <tr>
                                    <th scope="col" style={{width: '50px'}}>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="checkAll"
                                                   defaultValue="option"/>
                                        </div>
                                    </th>
                                    <th scope="col">{t("customer.table.customerNo")}</th>
                                    <th scope="col">{t("customer.table.name")}</th>
                                    <th scope="col">{t("customer.table.deliveryAddress")}</th>
                                    <th scope="col">{t("customer.table.countryCity")}</th>

                                    {/*<th scope="col" className="text-center">VIA</th>*/}
                                    {/*<th scope="col">Shipping service</th>*/}
                                    {/*<th scope="col"></th>*/}
                                </tr>
                                </thead>
                                <tbody className="list form-check-all">
                                { customers?.map((customer: CustomerInterface, index: number) => {
                                    return (
                                        <tr key={index}>

                                            <th scope="row">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" name="chk_child"
                                                           defaultValue="option1"/>
                                                </div>
                                            </th>
                                            <td>
                                                <a className={"cursor-pointer"} onClick={() => { setClickCustomerItem(customer.id) }}>
                                                    { customer.customerNumber }
                                                </a>
                                            </td>

                                            <td>
                                                <a className={"cursor-pointer"} onClick={() => { setClickCustomerItem(customer.id) }} >{ customer.name }</a>
                                            </td>
                                            <td>{ customer.address }</td>
                                            <td className="company_name">{ customer.country } / { customer.city }</td>
                                            {/*<td className="text-center"><img src={ProductIcon}/></td>*/}
                                            {/*<td>{ 'Pickup point' }</td>*/}

                                            {/*<td className="text-end">
                                                <a className={""} href="order_detail.html">
                                                    <i className="las la-pen fs-16 cl-br-ams"/>
                                                </a>
                                                <a className={""} href="">
                                                    <i className="ml-2 las la-times fs-16 cl-br-ams"/>
                                                </a>
                                            </td>*/}
                                        </tr>
                                    )
                                }) }
                                </tbody>
                            </table>
                        </div>
                        <QPagination
                            pagination={pagination}
                            setPagination={setPagination}
                            setLoading={setLoading}
                        />
                        {/*<div className="noresult">*/}
                        {/*    <div className="text-center">*/}
                        {/*        /!*<lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"*!/*/}
                        {/*        /!*           colors="primary:#121331,secondary:#08a88a"*!/*/}
                        {/*        /!*           style={{width: '75px', height: '75px'}}>*!/*/}
                        {/*        /!*</lord-icon>*!/*/}
                        {/*        <h5 className="mt-2">Sorry! No Result Found</h5>*/}
                        {/*        <p className="text-muted mb-0">We've searched more than 150+ contacts We did not find any*/}
                        {/*            contacts for you search.</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default React.memo(Customer);
