import {RouteInterface} from "../interfaces/RouteInterface";
import {RouteDefine} from "./RouteDefine";
import {Dashboard} from "../features/private/Dashboard";
import Order from "../features/private/Order";
import Customer from "../features/private/Customer";
import {Integrate} from "../features/private/Integrate";
import {Business} from "../features/private/Business";
import {Economic} from "../features/private/Economic";
import { Document } from "../features/private/Document";
import { MobilePay } from "../features/private/MobilePay";
import {Confirm} from "../features/public/Confirm/Confirm";
import Setup from "../features/public/Setup";
import {Config} from "../features/public/Config/Config";
// import Setup from "../features/public/Setup";

export const privateSetupRoute: Array<RouteInterface> = [
    // {path: RouteDefine.confirm, Component: Confirm},
    {path: RouteDefine.setup, Component: Setup},
    {path: RouteDefine.config, Component: Config},
    // {path: RouteDefine.document, Component: Document},
]
