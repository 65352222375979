export const RouteDefine = {
    resetPassword: '/reset-password',
    forgotPassword: '/forgot-password',

    setup: '/setup',
    login: '/login',
    adminLogin: '/admin-login',
    order: '/orders',
    admin: '/admin',
    product: '/products',
    customer: '/customers',
    dashboard: '/dashboard',
    integrate: '/integrate',
    integrateNew: '/integrate/new',
    integrateDetail: '/integrate/:id',
    shipment: '/shipment',
    document: '/document',
    log: '/log',

    userUpdate: '/user',
    userDetail: '/user/business-detail',
    business: '/business',
    businessUpdate: '/business-update',
    mobilePay:'/mobile-pay',
    economic: '/economic',

    confirm: '/confirm',
    register: '/register',
    config: '/config',
    shippingInformation:'/shipping-information',


    invoice: '/invoice',
    payment: '/payment',
    payout: '/payout',
    subscription: '/subscription',

    stripe: '/stripe',

    forbidden: '/403',
    notFound: '/404',
}
