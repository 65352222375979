import React, {useEffect, useState} from "react";
import QTextBox from "../../../../common/components/QTextBox";
import QDate from "../../../../common/components/QDate/QDate";
import QSelect from "../../../../common/components/QSelect";
import {CmsTypeInterface} from "../../../../interfaces/private/CmsTypeInterface";
import IntegrateApi from "../../../../common/api/functions/Integrate";
import {ResponseInterface} from "../../../../interfaces/ResponseInterface";
import {config} from "../../../../config";
import {Button, Col, Row} from "reactstrap";
import { Formik } from "formik";
import {IntegrateInterface, structIntegration} from "../../../../interfaces/private/IntegrateInterface";
import {SelectOptionInterface} from "../../../../interfaces/SelectOptionInterface";
import {QHelpers} from "../../../../common/helpers";
import QButton from "../../../../common/components/QButton";
import {useDispatch} from "react-redux";
import {setToast} from "../../../../app/rootReducer";

interface Props {
    model: IntegrateInterface|any,
    setOpen: Function,
    setLoadingFunc: Function,
}

const BodyIntegrateComponent: React.FC<Props> = ({model, setOpen, setLoadingFunc}) => {

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const [cmsType, setCmsType] = useState<Array<CmsTypeInterface>>([]);
    useEffect(() => {
        if (loading) {
            loadCmsType().then(async () => {
                await setLoading(false);
            });
        }
    }, [loading])

    const loadCmsType = async () => {
        try {
            await IntegrateApi.cmsType().then(async (response: ResponseInterface | any) => {
                if (response?.status === config.STATUS_CODE.OK) {
                    response = response?.data?.data;
                    setCmsType(
                        response || []
                    )
                }
            })

        } catch (err) {
        }
        if (loading) {
            await setLoading(false);
        }
    }

    const getDate = (date: string|any) => {
        if(!date) return '';

        if(typeof date === "string"){

            return new Date(date);
        }

        return  date;
    }

    return (
        <React.Fragment>
            <Formik
                initialValues={{...model}}
                onSubmit={(values: any) => {
                    values.order_last_update = QHelpers.date.formatDate(values.order_last_update);
                    if(values?.id > 0){
                        IntegrateApi.update(values?.id, values).then( async (response: ResponseInterface|any) => {
                            if(response){
                                await setOpen(false);
                                await setLoadingFunc(true);
                                await model({...structIntegration});
                                await dispatch(setToast({
                                    message: "Update integration success",
                                    type: config.TOAST_TYPE.SUCCESS
                                }))
                            }
                        })
                    }else{
                        IntegrateApi.create(values).then( async (response: ResponseInterface|any) => {
                            if(response){
                                await setOpen(false);
                                await model({...structIntegration});
                                await setLoadingFunc(true)
                                await dispatch(setToast({
                                    message: "Create integration success",
                                    type: config.TOAST_TYPE.SUCCESS
                                }))
                            }
                        })
                    }
                }}
            >
                { formik => {

                    return (
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <QSelect
                                        label={'Web shop name'}
                                        value={
                                            QHelpers.object.mapSelectOption(
                                                formik.values.cms_type,
                                                cmsType.map((c: CmsTypeInterface) => {
                                                    return {
                                                        label: c.name,
                                                        value: c.id,
                                                    }
                                                })
                                            )
                                        }
                                        options={cmsType.map((c: CmsTypeInterface) => {
                                            return {
                                                label: c.name,
                                                value: c.id,
                                            }
                                        })}
                                        onChange={(option: SelectOptionInterface) => {
                                            formik.setFieldValue('cms_type', option.value)
                                        }}
                                    />
                                    <QTextBox
                                        label={'Platform'}
                                        value={''}
                                        onChange={() => {}}
                                    />
                                    <QTextBox
                                        label={'Host'}
                                        value={formik.values.host}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('host', value)
                                        }}
                                    />

                                    <QTextBox
                                        label={'Key'}
                                        value={formik.values.key}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('key', value)
                                        }}
                                    />

                                    <QTextBox
                                        type={'password'}
                                        label={'Secret'}
                                        value={formik.values.secret}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('secret', value)
                                        }}
                                    />

                                    <QDate
                                        label={'Last update'}
                                        value={getDate(formik.values.order_last_update)}
                                        onChange={(date: Date|any) => {
                                            formik.setFieldValue('order_last_update', date);
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className={'d-flex justify-content-end gap-2'}>
                                        <QButton
                                            type={'default'}
                                            onClick={ async () => {
                                                await setOpen(false);
                                            }}
                                            content={'Cancel'}
                                        />

                                        <QButton
                                            type={'primary'}
                                            onClick={() => { formik.handleSubmit() }}
                                            content={'Submit'}
                                        />

                                    </div>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )
                } }
            </Formik>
        </React.Fragment>
    )
}

export default BodyIntegrateComponent
