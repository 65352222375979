import React from "react";
import ImgForbidden from "../../../assets/images/ams/img_403.png";
import {useHistory} from "react-router-dom";
import {RouteDefine} from "../../../routes/RouteDefine";
interface Props {

}

const Forbidden: React.FC<Props> = ({}) => {
    const history = useHistory()
    return (
        <React.Fragment>
            <div
                className="auth-page-wrapper auth-bg-cover-ams py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="auth-page-content overflow-hidden">
                    <div className="container-detail">
                        <div className="overflow-hidden p-5 bdr-10  m-auto">
                            <div className="row g-0">
                                <div className="col-lg-12">
                                    <div>
                                        <div className="d-flex justify-content-center">
                                            <div>
                                                <a href="#">
                                                    <img src={ ImgForbidden } width="640" />
                                                </a>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="mt-5 position-relative text-center">
                                                <h2 className="mb-4 mt-3 fs-23 fw-bold">You dont have permission!</h2>
                                                <p className="cl-bd">Server Error 500. We're not exactly sure what happened, but our servers say something is wrong.</p>
                                                <div className="mt-5">
                                                    <a
                                                       className="btn undefined btn bg-orange-zp bdr-10"
                                                       type="button"
                                                        onClick={() => { history.push(RouteDefine.invoice) }}
                                                    >
                                                        <i className="mdi mdi-home">

                                                        </i> Back to home
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Forbidden;
