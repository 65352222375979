import React, {useEffect, useState} from "react";
import QTextBox from "../../../../common/components/QTextBox";
import {ResponseInterface} from "../../../../interfaces/ResponseInterface";
import {config} from "../../../../config";
import {CustomerInterface} from "../../../../interfaces/private/CustomerInterface";
import CustomerApi from "../../../../common/api/functions/Customer";
import {Col, Container, Row} from "reactstrap";
import QButton from "../../../../common/components/QButton";
import QModal from "../../../../common/components/QModal";
import {useTranslation} from "react-i18next";

interface Props {
    open: number, // open > 0 show
    setOpen: Function,
}

const BodyCustomerDetailComponent: React.FC<Props> = ({open, setOpen}) => {
    const {t} = useTranslation();
    const id = open;
    const [customer, setCustomer] = useState <CustomerInterface|any>({})
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if(id > 0){
            CustomerApi.getDetail(id).then( async (response: ResponseInterface|any) => {
                if(response?.status === config.STATUS_CODE.OK){
                    await setCustomer(response?.data?.data || {})
                    setLoading(false)
                }
            })

        }

        return () => {
            setLoading(true);
        }
    }, [id])

    return (
        <React.Fragment>
            <QModal
                loading={loading}
                open={open > 0}
                setOpen={() => { setOpen(0) }}
                headerContent={t("customer.detail.title")}
                className = {'modal-md-zp'}
                bodyContent={(
                    <React.Fragment>
                        <Container>
                            <Row>
                                <Col>
                                    <QTextBox label={t("customer.detail.no")} disabled={true} value={customer.customerNumber} onChange={() => {}} />
                                </Col>
                                <Col>
                                    <QTextBox label={t("customer.detail.name")} disabled={true} value={customer.name} onChange={() => {}} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <QTextBox label={t("customer.detail.email")} disabled={true} value={customer.email} onChange={() => {}} />
                                </Col>
                                <Col>
                                    <QTextBox label={t("customer.detail.postalCode")} disabled={true} value={customer.zip} onChange={() => {}} />
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <QTextBox label={t("customer.detail.address")} disabled={true} value={customer.address} onChange={() => {}} />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <QTextBox label={t("customer.detail.currency")} disabled={true} value={customer.currency} onChange={() => {}} />
                                </Col>
                                <Col>
                                    <QTextBox label={t("customer.detail.city")} disabled={true} value={customer.city} onChange={() => {}} />
                                </Col>
                                <Col>
                                    <QTextBox label={t("customer.detail.country")} disabled={true} value={customer.country} onChange={() => {}} />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className={'d-flex justify-content-end gap-2'}>
                                        <QButton
                                            type={'default'}
                                            onClick={() => {  setOpen(0) }}
                                            content={t("common.button.cancel")}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </React.Fragment>
                )}
            />

        </React.Fragment>
    )
}

export default BodyCustomerDetailComponent
