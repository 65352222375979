import React, { useEffect, useState } from "react";
import QTextBox from "../../../common/components/QTextBox";
import { useFormik } from "formik";
import * as Yup from "yup";
import QLoading from "../../../common/components/QLoading";
import QButton from "../../../common/components/QButton";
import { ResponseInterface } from "../../../interfaces/ResponseInterface";
import { config } from "../../../config";
import { useHistory } from "react-router-dom";
import { RouteDefine } from "../../../routes/RouteDefine";
import { useDispatch } from "react-redux";
import RegisterApi from "../../../common/api/functions/Register";
import { setToast, setUserRole } from "../../../app/rootReducer";
import QModal from "../../../common/components/QModal";
import { QHelpers } from "../../../common/helpers";
import { useTranslation } from "react-i18next";
import QSelect from "../../../common/components/QSelect";
import { SelectOptionInterface } from "../../../interfaces/SelectOptionInterface";
import SetupApi from "../../../common/api/functions/Setup";
import UserApi from "../../../common/api/functions/User";
import { GeneralConstant } from "../../../common/constant/GeneralConstant";
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard';
import QIconCheck from "../../../common/components/QIcon/QIconCheck";
import styles from "../../public/Setup/index.module.css";
interface Layout {
    layoutNumber: number,
    name: string
}
export function Config() {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    let objectParams = QHelpers.object.convertParamUrlToObject(window.location.search);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');

    const [loading, setLoading] = useState(true);
    const { location } = history;
    let { state }: any = location;
    const [layouts, setLayouts] = useState([]);
    const [hookInfor, setHookInfor]: any = useState({});
    const [copied1, setCopied1] = useState(false);
    const [copied2, setCopied2] = useState(false);
    const [copied3, setCopied3] = useState(false);
    const [copied4, setCopied4] = useState(false);
    useEffect(() => {
        if (loading) {
            loadLayout().then(async () => {
                await setLoading(false);
            });
            loadWebhookInformation().then(async () => {
                await setLoading(false);
            });
        }
    }, [loading])

    const loadLayout = async () => {
        try {
            await SetupApi.getLayouts(state.company.token).then(async (response: ResponseInterface | any) => {
                if (response?.status === config.STATUS_CODE.OK) {
                    setLayouts(
                        response?.data?.data || {}
                    )
                }
            })

        } catch (err) {
        }
        if (loading) {
            await setLoading(false);
        }
    }
    const loadWebhookInformation = async () => {
        try {
            await SetupApi.getWebhookInformation(state.company.token, state.agreementNumber).then(async (response: ResponseInterface | any) => {
                if (response?.status === config.STATUS_CODE.OK) {
                    setHookInfor(
                        response?.data?.data || {}
                    )
                }
            })

        } catch (err) {
        }
        if (loading) {
            await setLoading(false);
        }
    }
    let getNameLayout = (layouts: any, number: number) => {
        if (layouts.length === 0) {
            return null;
        }
        let arrayObject = layouts.filter((l: any) => l.layoutNumber == number);
        let object: Layout = arrayObject[0];
        return object ? object.name : '';
    }
    let model = {
        token: objectParams?.token
    }
    const formik = useFormik({
        initialValues: {
            email: state === undefined ? '' : state.company.email,
            name: state === undefined ? '' : state.company.name,
            company_name: '',
            address: state === undefined ? '' : state.company.addressLine1,
            zip: state === undefined ? '' : state.company.zip,
            city: state === undefined ? '' : state.company.city,
            country: state === undefined ? '' : state.company.country,
            phone: state === undefined ? '' : state.company.phoneNumber,
            website: state === undefined ? '' : state.company.website,
            cvr_no: state === undefined ? '' : state.company.companyIdentificationNumber,
            token: state === undefined ? '' : state.company.token,
            layout: state === undefined ? '' : state.company.layout,
            account_number: state === undefined ? '' : state.agreementNumber,
            mobile_phone: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().required().email(),
            name: Yup.string().required(),
            layout: Yup.string().required(),
            phone: Yup.string().required(),
            cvr_no: Yup.string().required(),
            token: Yup.string().required(),
            address: Yup.string().required(),
            account_number: Yup.string().required(),
            mobile_phone: Yup.string().required(),
            zip: Yup.string().required(),
            city: Yup.string().required(),
            country: Yup.string().required()
        }),
        onSubmit: async (values, { setFieldError }) => {
            await setLoading(true);
            await UserApi.registerByEconomic(values).then(async (response: ResponseInterface | any) => {
                if (response?.status === config.STATUS_CODE.OK) {
                    await localStorage.setItem(
                        config.LOCAL_STORE.ACCOUNT,
                        JSON.stringify(response.data.data),
                    )
                    await localStorage.setItem(
                        config.LOCAL_STORE.ROLE,
                        QHelpers.string.encrypt(
                            response?.data?.data?.user?.is_admin === 1
                                ? GeneralConstant.user.role.super_admin
                                : GeneralConstant.user.role.user
                        )
                    )

                    if (response?.data?.data?.user?.is_admin === 1) {
                        await dispatch(setUserRole(GeneralConstant.user.role.super_admin));
                        return history.push(RouteDefine.admin);
                    } else if (response?.data?.data?.user?.is_logged === 0) {
                        await dispatch(setUserRole(GeneralConstant.user.role.user));
                        return history.push(RouteDefine.setup, response.data.data);
                    } else {
                        await dispatch(setUserRole(GeneralConstant.user.role.user));
                        return history.push(RouteDefine.order);
                    }
                }
            }, (response) => {
                if (typeof response.data.message === "string") {
                    // setMessageErr(response?.data?.message);
                    dispatch(setToast({
                        message: response.data?.message,
                        type: config.TOAST_TYPE.ERROR,
                    }))
                } else {
                    let message = response.data.message;
                    for (let key in message) {
                        setFieldError(key, message[key][0]);
                    }
                }
            }).catch(() => {
                dispatch(setToast({
                    message: t("auth.register.create_fail"),
                    type: config.TOAST_TYPE.ERROR,
                }))
            });


            await setLoading(false);
        }
    })
    console.log('layout', formik.values);
    return (
        <div className="container">
            <QModal
                open={open}
                setOpen={() => {
                    setOpen(false);
                }}
                loading={loading}
                headerContent={t("auth.register.create_new_account")}
                bodyContent={(
                    <React.Fragment>
                        {message}
                    </React.Fragment>
                )}
            />
            <div className="mt-4 position-relative">
                <QLoading loading={loading} />

                <form action="" method="">
                    <h2 className="mb-4 mt-3  fs-32 fw-400 cl-orange-zp"> Opsætning første gang</h2>

                    <div className="row">
                        <h4 className="mb-4 mt-3  fs-32 fw-400 cl-orange-zp">1. e-conomic information</h4>
                        <div className="col-6">
                            <QTextBox
                                label={'Firma navn'}
                                placeholder={''}
                                // className={'Enter username'}
                                value={formik.values.name}
                                className={'form-control-zp bg-transparent bd-bottom'}
                                onChange={(value: string) => {
                                    formik.setFieldValue('name', value)
                                }}
                                error={formik.touched?.name && formik.errors?.name}
                            />
                            <QTextBox
                                label={'E-mail adresse'}
                                placeholder={''}
                                // className={'Enter username'}
                                value={formik.values.email}
                                className={'form-control-zp bg-transparent bd-bottom'}
                                onChange={(value: string) => {
                                    formik.setFieldValue('email', value)
                                }}
                                error={formik.touched?.email && formik.errors?.email}
                            />
                            <QTextBox
                                label={'Telefon nr.'}
                                placeholder={''}
                                // className={'Enter username'}
                                value={formik.values.phone}
                                className={'form-control-zp bg-transparent bd-bottom'}
                                onChange={(value: string) => {
                                    formik.setFieldValue('phone', value)
                                }}
                                error={formik.touched?.phone && formik.errors?.phone}
                            />
                            <QTextBox
                                label={'CVR nr.'}
                                placeholder={''}
                                value={formik.values.cvr_no}
                                className={'form-control-zp bg-transparent bd-bottom'}
                                onChange={(value: string) => {
                                    formik.setFieldValue('cvr_no', value)
                                }}
                                error={formik.touched?.cvr_no && formik.errors?.cvr_no}
                            />
                            <QTextBox
                                label={'Website'}
                                placeholder={''}
                                value={formik.values.website}
                                className={'form-control-zp bg-transparent bd-bottom'}
                                onChange={(value: string) => {
                                    formik.setFieldValue('website', value)
                                }}
                                error={formik.touched?.website && formik.errors?.website}
                            />
                            <QTextBox
                                label={'E-conomic'}
                                placeholder={''}
                                value={formik.values.token}
                                className={'form-control-zp bg-transparent bd-bottom'}
                                onChange={(value: string) => {
                                    formik.setFieldValue('economic_token', value)
                                }}
                                error={formik.touched?.token && formik.errors?.token}
                            />
                        </div>
                        <div className="col-6">
                            <QSelect
                                label={'Vælg layout her til brug for MobilePay'}
                                value={
                                    formik.values.layout ? { label: getNameLayout(layouts, formik.values.layout), value: formik.values.layout } : (
                                        QHelpers.object.mapSelectOption(
                                            formik.values.layout,
                                            layouts?.map((c: Layout) => ({ label: c.name, value: c.layoutNumber }))
                                        )
                                    )
                                }
                                options={
                                    layouts?.map((c: Layout) => ({ label: c.name, value: c.layoutNumber }))
                                }
                                onChange={(option: SelectOptionInterface) => {
                                    formik.setFieldValue('layout', option.value)
                                }}
                                error={formik.touched?.layout && formik.errors?.layout}
                            />

                            <QTextBox
                                label={'Adresse'}
                                placeholder={''}
                                value={formik.values.address}
                                className={'form-control-zp bg-transparent bd-bottom'}
                                onChange={(value: string) => {
                                    formik.setFieldValue('address', value)
                                }}
                                error={formik.touched?.address && formik.errors?.address}
                            />
                            <QTextBox
                                label={'Post nr.'}
                                placeholder={''}
                                value={formik.values.zip}
                                className={'form-control-zp bg-transparent bd-bottom'}
                                onChange={(value: string) => {
                                    formik.setFieldValue('zip', value)
                                }}
                                error={formik.touched?.zip && formik.errors?.zip}
                            />
                            <QTextBox
                                label={'By'}
                                placeholder={''}
                                value={formik.values.city}
                                className={'form-control-zp bg-transparent bd-bottom'}
                                onChange={(value: string) => {
                                    formik.setFieldValue('city', value)
                                }}
                                error={formik.touched?.city && formik.errors?.city}
                            />

                            <QTextBox
                                label={'Land'}
                                placeholder={''}
                                value={formik.values.country}
                                className={'form-control-zp bg-transparent bd-bottom'}
                                onChange={(value: string) => {
                                    formik.setFieldValue('country', value)
                                }}
                                error={formik.touched?.country && formik.errors?.country}
                            />
                        </div>
                        <div className="row">
                            <h4 className="mb-4 mt-3  fs-32 fw-400 cl-orange-zp">2. IMPORTANT NOTICE</h4>
                            <div className="col-12">
                                You need to read the instruction manual before proceeding with the next steps. <a className="text-danger" href={'/document#layout'} target={'_blank'}>Go here</a>
                            </div>
                        </div>
                        <div className="row">
                            <h4 className="mb-4 mt-3  fs-32 fw-400 cl-orange-zp">3. MobilePay information</h4>
                            <div className="col-12">
                                <QTextBox
                                    label={'Telefon'}
                                    value={formik.values.mobile_phone}
                                    className={'form-control-zp bg-transparent bd-bottom'}
                                    onChange={(value: string) => {
                                        formik.setFieldValue('mobile_phone', value)
                                    }}
                                    error={formik.touched?.mobile_phone && formik.errors?.mobile_phone}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <h4 className="mb-4 mt-3  fs-32 fw-400 cl-orange-zp">4. Economic Webhook</h4>
                            <div className="col-12">
                                <label htmlFor=""><b>Webhook navn</b></label>
                                <div className="row">
                                    <div className="col-md-8">
                                        <p>{hookInfor?.name}</p>
                                    </div>
                                    <div className="col-md-4 text-end">
                                        <CopyToClipboard text={hookInfor?.name}>
                                            <a className={'cursor-pointer'}>
                                                <i className="las la-copy fs-24 cl-br-ams ml-2"
                                                    onClick={() => setCopied1(true)}
                                                />
                                            </a>
                                        </CopyToClipboard>
                                        {
                                            copied1 && (
                                                <div className={`${styles.popupCopied} shadow-lg`}>
                                                    <span className="text-success">
                                                        {t("economic.copied")} <QIconCheck className={'text-success'} />
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <label htmlFor=""><b>Webhook Url</b></label>
                                <div className="row">
                                    <div className="col-md-8">
                                        <p>{hookInfor?.url}</p>
                                    </div>
                                    <div className="col-md-4 text-end">
                                        <CopyToClipboard text={hookInfor?.url}>
                                            <a className={'cursor-pointer'}>
                                                <i className="las la-copy fs-24 cl-br-ams ml-2"
                                                    onClick={() => setCopied2(true)}
                                                />
                                            </a>
                                        </CopyToClipboard>
                                        {
                                            copied2 && (
                                                <div className={`${styles.popupCopied} shadow-lg`}>
                                                    <span className="text-success">
                                                        {t("economic.copied")} <QIconCheck className={'text-success'} />
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <label htmlFor=""><b>Webhook Type</b></label>
                                <div className="row">
                                    <div className="col-md-8">
                                        <p>{hookInfor?.type}</p>
                                    </div>
                                    <div className="col-md-4 text-end">
                                        <CopyToClipboard text={hookInfor?.type}>
                                            <a className={'cursor-pointer'}>
                                                <i className="las la-copy fs-24 cl-br-ams ml-2"
                                                    onClick={() => setCopied4(true)}
                                                />
                                            </a>
                                        </CopyToClipboard>
                                        {
                                            copied2 && (
                                                <div className={`${styles.popupCopied} shadow-lg`}>
                                                    <span className="text-success">
                                                        {t("economic.copied")} <QIconCheck className={'text-success'} />
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <label htmlFor=""><b>Webhook Data Post</b></label>
                                <div className="row">
                                    <div className="col-md-8">
                                        <p>{hookInfor?.data_posted}</p>
                                    </div>
                                    <div className="col-md-4 text-end">
                                        <CopyToClipboard text={hookInfor?.data_posted}>
                                            <a className={'cursor-pointer'}>
                                                <i className="las la-copy fs-24 cl-br-ams ml-2"
                                                    onClick={() => setCopied3(true)}
                                                />
                                            </a>
                                        </CopyToClipboard>
                                        {
                                            copied3 && (
                                                <div className={`${styles.popupCopied} shadow-lg`}>
                                                    <span className="text-success">
                                                        {t("economic.copied")} <QIconCheck className={'text-success'} />
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="mt-4" style={{marginBottom:"10px"}}>
                            <QButton
                                content={t("auth.register.create_account")}
                                type={'success'}
                                width={'w-100'}
                                size={''}
                                className={'btn bg-orange-zp w-100 bdr-10 fs-16'}
                                onClick={() => {
                                    formik.handleSubmit()
                                }}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
