import {config} from "../../config";

const localStorageSetItem = (key: string, value: string|number|object|Array<any>|any) => {
    localStorage.setItem(key, value);
}

const localStorageGetItem = (key: string) => {
    return localStorage.getItem(key);

}

const sessionStorageSetItem = (key: string, value: string|number|object|Array<any>|any) => {
    sessionStorage.setItem(key, value);
}

const sessionStorageGetItem = (key: string) => {
    return sessionStorage.getItem(key);
}

const getAccessToken = () => {
    let account : any = localStorageGetItem(config.LOCAL_STORE.ACCOUNT);
    try {
        account = JSON.parse(account);
        return account.access_token;
    }catch (err){  }
    return null;
}


export const __store = {
    localStorageGetItem,
    sessionStorageGetItem,
    localStorageSetItem,
    sessionStorageSetItem,
    getAccessToken,
}
