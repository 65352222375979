import React, {useEffect, useState} from "react";
import {PaginationInterface, structPagination} from "../../../interfaces/PaginationInterface";
import {IntegrateInterface, structIntegration} from "../../../interfaces/private/IntegrateInterface";
import {ResponseInterface} from "../../../interfaces/ResponseInterface";
import QModal from "../../../common/components/QModal";
import QLoading from "../../../common/components/QLoading";
import QPagination from "../../../common/components/QPaging";
import IntegrateApi from "../../../common/api/functions/Integrate";
import BodyIntegrateComponent from "./components/BodyIntegrateComponent";
import {UseSecondLayout} from "../../../context/LayoutContext";
import Moment from "react-moment";
import {config} from "../../../config";

export function Integrate() {
    const [integrates, setIntegrates] = useState<Array<IntegrateInterface>>([]);
    const [integrate, setIntegrate] = useState<IntegrateInterface | any>({...structIntegration});
    const [pagination, setPagination] = useState<PaginationInterface>({...structPagination})
    const [loading, setLoading] = useState(true);
    const [createNewClick, setCreateNewClick] = useState(false);
    UseSecondLayout();
    useEffect(() => {

        if (loading) {
            loadIntegrate().then(async () => {
                await setLoading(false);
            });
        }
    }, [loading])

    const loadIntegrate = async () => {
        try {
            await IntegrateApi.list(pagination).then(async (response: ResponseInterface | any) => {
                if (response?.status === config.STATUS_CODE.OK) {
                    response = response?.data?.data;
                    setIntegrates(
                        response?.data || []
                    )

                    setPagination({
                        ...pagination,
                        page: response.current_page,
                        total: response.total,
                        per_page: response.per_page
                    })
                }
            })

        } catch (err) {
        }
        if (loading) {
            await setLoading(false);
        }
    }

    return (
        <React.Fragment>
            <QModal
                open={createNewClick}
                setOpen={async () => {
                    await setIntegrate({...structIntegration});
                    await setCreateNewClick(false)
                }}
                headerContent={'Integration'}
                bodyContent={(
                    <BodyIntegrateComponent
                        model={integrate}
                        setOpen={ async () => {await setIntegrate({...structIntegration}); await setCreateNewClick(false) }}
                        setLoadingFunc={setLoading}
                    />
                )}
            />
            <div className="col-md-10 relative">
                <QLoading loading={loading}/>
                <div className="row ">

                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <div className="page-title-name">Integration</div>
                            <div className="page-title-right">
                                <div className="d-flex">
                                    <a className="btn btn-sm  bg-orange-zp ml-3" onClick={() => {
                                        setCreateNewClick(true)
                                    }}>
                                        <span>
                                            <i className="mdi mdi-plus mr-2"/>
                                            Create Integration
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="mb-3">
                            <table className="table align-middle table-nowrap mb-0 relative" id="customerTable">
                                <thead className="table-light">
                                <tr>

                                    <th data-sort="customer" scope="col">HOST</th>
                                    <th data-sort="order_no" scope="col">PLATFORM</th>
                                    <th data-sort="channel" scope="col">STATUS</th>
                                    <th data-sort="ordered" scope="col">LAST IMPORT</th>
                                    <th scope="col"/>
                                </tr>
                                </thead>
                                <tbody className="list form-check-all">
                                {integrates?.map((integrate: IntegrateInterface, index: number) => {
                                    return (
                                        <tr key={index + 1}>
                                            <td>{integrate.host}</td>
                                            <td>{integrate.cms_type == 1 ? 'Woocomerce' : 'Shopify'}</td>
                                            <td>{integrate.status == 1 ? 'Active' : 'In-active'}</td>
                                            <td><Moment date={integrate.order_last_update} format="DD.MM.YY hh:mm"/></td>
                                            <td className="text-end">
                                                <a className={"cursor-pointer"} onClick={async () => {
                                                    await setIntegrate(integrate)
                                                    await setCreateNewClick(true);
                                                }}>
                                                    <i className="las la-pen fs-16 cl-br-ams"/>
                                                </a>
                                                <a className={""} href="javascript:void (0)">
                                                    <i className="ml-2 las la-times fs-16 cl-br-ams"/>
                                                </a>
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                        <QPagination
                            pagination={pagination}
                            setPagination={setPagination}
                            setLoading={setLoading}
                        />
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

// @ts-ignore
export default React.memo(Integrate);
