import React, { useCallback, useState, useEffect } from "react";
import QTextBox from "../../../common/components/QTextBox";
import { useFormik } from "formik";
import * as yup from "yup";
import { getValidation, SetupConfig } from "./config";
import QButton from "../../../common/components/QButton";
import AuthApi from "../../../common/api/functions/Auth";
import { config } from "../../../config";
import {useDispatch, useSelector} from "react-redux";
import {setLoading, setToast} from "../../../app/rootReducer";
import { ResponseInterface } from "../../../interfaces/ResponseInterface";
import { useHistory } from "react-router-dom";
import { RouteDefine } from "../../../routes/RouteDefine";
import SetupApi from "../../../common/api/functions/Setup";
import {SetupInterface} from "../../../interfaces/private/SetupInterface";
import QLoading from "../../../common/components/QLoading";
import {RootState} from "../../../app/store";
import {Progress} from "reactstrap";

interface Props {

}

const initialValues: any = {

}

const Setup: React.FC<Props> = ({ }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [step, setStep] = useState(1);
    const { location } = history;
    // const [loading, setLoading] = useDispatch(false);
    const {loading} = useSelector((state:RootState) => state.rootReducer);

    const formik = useFormik({
        initialValues: { ...initialValues },
        enableReinitialize: true,
        validationSchema: getValidation(step, yup),
        onSubmit: async (values) => {
            dispatch(setLoading(true));
            if (step < 5) {
                setStep(step + 1)
            } else {
                await SetupApi.update(values as SetupInterface).then(async (response:ResponseInterface|any)=>{
                    dispatch(setLoading(false));
                   if(!response?.data?.error){
                       setStep(6);
                   }else{
                       setStep(7);
                   }

                })
                /*let model = {
                    economic_info: {
                        economic_token: values.economic_token,
                    },
                    company_ship_info: {
                        gls_username: values.gls_username,
                        gls_password: values.gls_password,
                        gls_customer_id: values.gls_customer_id,
                        gls_contact_id: values.gls_contact_id,
                    }
                }

                let response: ResponseInterface | any = await AuthApi.setupFirstLogin(model)

                if (response?.status === config.STATUS_CODE.OK) {
                    dispatch(setToast({
                        message: response?.data?.message,
                        type: config.TOAST_TYPE.SUCCESS,
                    }))

                    // history.push(RouteDefine.order)
                    setStep(4);
                } else {
                    // dispatch(setToast({
                    //     message: response?.data?.message,
                    //     type: config.TOAST_TYPE.ERROR,
                    // }))
                    setStep(5)
                }*/
            }
        }
    })

    return (
        <React.Fragment>
            <QLoading loading={loading} />
            <div className="mt-5 position-relative">
                <Progress style={{
                    height: '15px'
                }}
                          color="danger" value={100/6*(step)}>{step}/6</Progress>
                <form method="">
                    {SetupConfig(step, formik)}
                    <div className="mt-5">
                        {
                            step < 6 ? (
                                <QButton
                                    className={'btn bdr-10 fs-16'}
                                    width={100}
                                    type={'primary'}
                                    content={step < 5 ? "Next" : "Submit"}
                                    size={''}
                                    onClick={() => {

                                        formik.handleSubmit();
                                    }}
                                />
                            ) : null
                        }
                        {
                            step > 1 && step < 6 ? (
                                <QButton
                                    className={'btn bdr-10 fs-16 mt-3'}
                                    width={100}
                                    content={"Back"}
                                    size={''}
                                    onClick={() => {
                                        setStep(step - 1)
                                    }}
                                />
                            ) : null
                        }
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}

export default Setup;
