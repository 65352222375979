import React, {useRef, useState} from "react";

interface Props {
    placeholder?: string,
    onChange: Function,
    delayTime?: number,
    valueDefault?: string,
}

const QSearch: React.FC<Props> = ({
    placeholder = 'Search for anything...',
    onChange,
    valueDefault = '',
    delayTime= 1000
                                  }) => {

    const typingTimeoutRef: any = useRef(null);

    const [value, setValue] = useState(valueDefault);

    const handleSearchTermChange = (event: Event|any, isEnter: boolean) => {
        setValue(event.target.value);
        if(typingTimeoutRef.current){
            clearTimeout(typingTimeoutRef.current);
        }

        if(isEnter){
            onChange(event.target.value);
        }else{
            typingTimeoutRef.current = setTimeout(() => {
                onChange(event.target.value);
            }, delayTime)
        }
    }

    return (
        <React.Fragment>
            <div className="flex-shrink-0">
                <div className="search-box">
                    <input
                        type="text"
                        className="form-control search bd-none bdr-10 bg-search w-4x"
                        placeholder={placeholder}
                        onChange={(e: Event|any) => {
                            handleSearchTermChange(e, false)
                        }}
                        onKeyPress={(e: Event|any) => {
                            if(e.key === "Enter"){
                                handleSearchTermChange(e, true);
                            }
                        }}
                    />
                    <i className="ri-search-line search-icon fs-12"/>
                </div>
            </div>
        </React.Fragment>
    )
}

export default QSearch;