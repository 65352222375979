export const config = {
    HASH: "ebe587e6c53fa4ed7db0a70fc825d406cc522a1b",
    API_URL: process.env.REACT_APP_API_URL,
    // API_URL: "https://2app-stripe.srv871186895.internet-server.dk/backend/public/api",
    APP_NAME: "2app stripe e-conomic",
    STATUS_CODE: {
        OK: 200,
        BAD_REQUEST: 400,
        FORBIDDEN: 403,
        SERVER_ERROR: 500,
    },
    LOCAL_STORE: {
        ACCOUNT: 'account',
        ACCOUNT_SWITCH: 'aw',
        ACCESS_TOKEN: 'access_token',
        ROLE: 'r',
    },
    TOAST_TYPE: {
        INFO: 'info',
        SUCCESS: 'success',
        ERROR: 'error',
        WARNING: 'warning',
    },
    INTEGRATION: {
        ECONOMIC: {
            REDIRECT_URL: 'https://shipping.2app.dk/economic',
            PUBLIC_TOKEN: "BXpwe4dnibS6HSebV73mZREABhB2VFDWSiCo0oxuIKk",
            REDIRECT_URL_SETUP: "https://shipping.2app.dk/setup",
        },
        STRIPE:{
            CONNECT_URL:`${process.env.REACT_APP_STRIPE_OAUTH_URL}&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}`
        }

    }
}
