import React, { useState } from "react";
import { Props } from "react-select";
import { RouteDefine } from "../../routes/RouteDefine";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


const Navbar: React.FC<Props> = () => {
    let urlPath = window.location.pathname;
    const [stateUrl, setStateUrl] = useState(window.location.pathname);
    const { t } = useTranslation();

    return <React.Fragment>
        <div className="app-menu navbar-menu">
            <div id="scrollbar">
                <div className="container-fluid">
                    <ul className="navbar-nav" id="navbar-nav">
                        {/*<li className="nav-item">*/}
                        {/*    <Link to={RouteDefine.order} className={"nav-link menu-link menu-link-ams fs-14" + (RouteDefine.order === urlPath ? ' active' : '')}>{t("common.navbar.log")}</Link>*/}
                        {/*</li>*/}
                         <li className="nav-item">
                            <Link className={"nav-link menu-link menu-link-ams " + ((RouteDefine.invoice === urlPath || RouteDefine.payout === urlPath || RouteDefine.payment === urlPath || RouteDefine.subscription === urlPath)
                                ? ' active' : '')} to={RouteDefine.invoice}>
                                <span data-key="t-home" className="fs-14">{t("common.navbar.integrationManagement")}</span>
                            </Link>
                            <div className="menu-dropdown p-0">
                                <ul className="nav nav-sm flex-column">
                                    <li className={`nav-item ${stateUrl === RouteDefine.invoice ? ' active' : ''}`}
                                        onClick={() => {
                                            setStateUrl(RouteDefine.invoice)
                                        }}
                                    >
                                        <Link className={'nav-link fs-14 justify-content-center bd-bottom'} to={RouteDefine.invoice}>{t("common.navbar.invoices")}</Link>
                                    </li>
                                    <li className={`nav-item ${stateUrl === RouteDefine.payment ? ' active' : ''}`}
                                        onClick={() => {
                                            setStateUrl(RouteDefine.payment)
                                        }}
                                    >
                                        <Link className={'nav-link fs-14 justify-content-center bd-bottom'} to={RouteDefine.payment}>{t("common.navbar.payments")}</Link>
                                    </li>
                                    <li className={`nav-item ${stateUrl === RouteDefine.payout ? ' active' : ''}`}
                                        onClick={() => {
                                            setStateUrl(RouteDefine.payout)
                                        }}
                                    >
                                        <Link className={'nav-link fs-14 justify-content-center bd-bottom'} to={RouteDefine.payout}>{t("common.navbar.payouts")}</Link>
                                    </li>
                                    <li className={`nav-item ${stateUrl === RouteDefine.subscription ? ' active' : ''}`}
                                        onClick={() => {
                                            setStateUrl(RouteDefine.subscription)
                                        }}
                                    >
                                        <Link className={'nav-link fs-14 justify-content-center bd-bottom'} to={RouteDefine.subscription}>{t("common.navbar.subscriptions")}</Link>
                                    </li>

                                </ul>
                            </div>
                        </li>
                        {/* end Dashboard Menu */}
                        <li className="nav-item">
                            <Link to={RouteDefine.business} className="nav-link menu-link menu-link-ams fs-14">{t("common.navbar.settings")}</Link>
                        </li>
                        {/*<li className="nav-item">
                            <a href={'/document'} target="_blank" className="nav-link menu-link menu-link-ams fs-14">{t("common.navbar.document")}</a>
                        </li>*/}
                        {/* <li className={"nav-item"}>
                            <Link
                                to={RouteDefine.shipment}
                                className={"nav-link menu-link menu-link-ams fs-14" + (RouteDefine.shipment === urlPath ? ' active' : '')}
                            >
                                {t("common.navbar.shippedInformation")}
                            </Link>
                        </li> */}
                        {/* end Dashboard Menu */}
                    </ul>
                </div>
                {/* Sidebar */}
            </div>
            <div className="sidebar-background" />
        </div>
    </React.Fragment>;
};
export default Navbar;
