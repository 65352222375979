import React from "react";
import QTextBox from "../../../../common/components/QTextBox";
import {ResponseInterface} from "../../../../interfaces/ResponseInterface";
import {config} from "../../../../config";
import {Col, Row} from "reactstrap";
import {Formik} from "formik";
import QButton from "../../../../common/components/QButton";
import {useDispatch} from "react-redux";
import {setToast} from "../../../../app/rootReducer";
import SetupApi from "../../../../common/api/functions/Setup";
import {SetupInterface} from "../../../../interfaces/private/SetupInterface";
import {useHistory} from "react-router-dom";
import {QHelpers} from "../../../../common/helpers";
import {RouteDefine} from "../../../../routes/RouteDefine";
import {useTranslation} from "react-i18next";

interface Props {
    model: SetupInterface,
    setOpen: Function,
    setLoadingFunc: Function,
    setSetup: Function,
}

const BodyEconomicComponent: React.FC<Props> = ({model, setOpen, setLoadingFunc, setSetup}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {t } = useTranslation();
    return (
        <React.Fragment>
            <Formik
                initialValues={{...model}}
                onSubmit={(values: any) => {
                    SetupApi.update(values).then(async (response: ResponseInterface | any) => {
                        if (response.status === config.STATUS_CODE.OK) {

                            let objectParams = QHelpers.object.convertParamUrlToObject(window.location.search)
                            if(objectParams?.token){
                                history.push(RouteDefine.economic)
                            }

                            await setOpen(false);
                            await setLoadingFunc(true);
                            await dispatch(setToast({
                                message: "Update success",
                                type: config.TOAST_TYPE.SUCCESS
                            }))
                        }
                    })
                }}
            >
                {formik => {

                    return (
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <QTextBox
                                        label={t("economic.token")}
                                        value={formik.values.economic_token}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('economic_token', value)
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className={'d-flex justify-content-end gap-2'}>
                                        <QButton
                                            type={'default'}
                                            onClick={() => {
                                                setOpen(false)
                                            }}
                                            content={t("common.button.cancel")}
                                        />

                                        <QButton
                                            type={'primary'}
                                            onClick={() => {
                                                formik.handleSubmit()
                                            }}
                                            content={t("common.button.submit")}
                                        />

                                    </div>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )
                }}
            </Formik>
        </React.Fragment>
    )
}

export default BodyEconomicComponent
