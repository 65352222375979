import React, {useEffect, useState} from "react";
import {config} from "../../../config";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {RouteDefine} from "../../../routes/RouteDefine";
import {QHelpers} from "../../../common/helpers";
import QModal from "../../../common/components/QModal";
import webhookEconomicForm from "../../../assets/images/logo/2app-stripe-big-logo.png";
import moment from "moment";
import BodyModalSubscriptionTermsComponent from "./BodyModalSubscriptionTermsComponent";


interface LocationState {
    agreementNumber: string;
    user: User;
    // add other properties if needed
}

interface User {
    name: string;
    // add other properties if needed
}

export function Confirm() {
    const history = useHistory();
    const location = useLocation();
    let response = location.state as LocationState;

    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    let objectParams = QHelpers.object.convertParamUrlToObject(window.location.search);
    let localItemInfo: any = QHelpers.store.localStorageGetItem(config.LOCAL_STORE.ACCOUNT);
    try {
        localItemInfo = JSON.parse(localItemInfo);
    } catch (err) {
    }

    let {access_token, step} = localItemInfo;

    useEffect(() => {

        if (loading) {
            // loadRedirect().then(async () => {
            //     await setLoading(false);
            // });
        }
    }, [loading])

    // const loadRedirect = async () => {
    //     try {
    //         let model = {
    //             token: objectParams?.token
    //         }
    //         await UserApi.getInfoCompany(model).then(async (response: ResponseInterface | any) => {
    //             if (response.status === config.STATUS_CODE.OK) {
    //                 response = response.data.data;
    //                 let modelUser = {
    //                     email: response.email
    //                 }
    //                 await UserApi.checkExistEmail(modelUser).then(async (responseUser: ResponseInterface | any) => {
    //                     if (responseUser.status === config.STATUS_CODE.OK) {
    //                         if (responseUser.data.data) {
    //                             return history.push(RouteDefine.order + '?token=' + model.token, response);
    //                         }
    //                         else {
    //                             return history.push(RouteDefine.register + '?token=' + model.token, response);

    //                         }
    //                     }

    //                 })


    //             }
    //         })

    //     } catch (err) {
    //     }
    //     if (loading) {
    //         await setLoading(false);
    //     }
    // }
    let redirectUrl = config.INTEGRATION.STRIPE.CONNECT_URL + '&state=' + access_token + '__' + moment().format('YYYYMMDDHHmmss') + '&redirect_uri='+process.env.REACT_APP_STRIPE_REDIRECT_URL;
    if (step === 2) {
        redirectUrl = RouteDefine.setup;
    } else if (step === -1)
        redirectUrl = RouteDefine.order;

    return (
        <React.Fragment>
            <QModal
                open={openModal}
                setOpen={setOpenModal}
                headerContent={'Subscription Terms'}
                bodyContent={(
                    <BodyModalSubscriptionTermsComponent />
                )}

            />
            <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="auth-page-content overflow-hidden">
                    <div className="container-detail">
                        <div className="overflow-hidden p-5 bdr-10  m-auto">
                            <div className="row g-0">
                                <div className="col-lg-12">
                                    <div>
                                        <div className="d-flex justify-content-center">
                                            <div><a href="#"><img src={webhookEconomicForm} height={150}/></a></div>
                                        </div>
                                        <div>
                                            <div className="mt-5 position-relative text-center">
                                                <h2 className="mb-4 mt-3 fs-23 fw-bold">You’re almost there!</h2>
                                                <p className="cl-bd">We have created you with us with information from
                                                    the e-conomic account: <br/>
                                                    {response?.user.name} on agreement no. {response?.agreementNumber}
                                                </p>
                                                <div className="mt-1">
                                                    {/*<a href="" className="btn undefined btn bg-orange-zp bdr-10" type="button" onClick={() => { history.push(RouteDefine.config+'?economic_token='+token, response) }} >*/}
                                                    {step === 1 ? (
                                                        <a href={redirectUrl}
                                                           className="btn undefined btn bg-orange-zp bdr-10"
                                                           type="button">
                                                            Connect to Your Stripe Account right now!
                                                        </a>
                                                    ) : step === 2 ? (
                                                        <a onClick={()=>history.push(redirectUrl)}
                                                           className="btn undefined btn bg-orange-zp bdr-10"
                                                           type="button">
                                                            You need to complete some setup before using.
                                                        </a>
                                                    ) : step === -1 ? (<a onClick={()=>history.push(redirectUrl)}
                                                                          className="btn undefined btn bg-orange-zp bdr-10"
                                                                          type="button">
                                                        You have already completed everything, so you will be redirected to the dashboard now or after 5 seconds.
                                                    </a>) : null}

                                                </div>
                                                <p className="mt-3"><a className={'text-danger'} href={'#'} onClick={(e)=>{e.preventDefault();setOpenModal(true);}}>Click here</a> to see our subscription terms, which we
                                                    also send to you in our welcome email.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}
