import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {SetupInterface} from "../../../interfaces/private/SetupInterface";
import {UseSecondLayout} from "../../../context/LayoutContext";
import SetupApi from "../../../common/api/functions/Setup";
import {ResponseInterface} from "../../../interfaces/ResponseInterface";
import {config} from "../../../config";
import QLoading from "../../../common/components/QLoading";

const Stripe = () => {

    const { t } = useTranslation();
    const [setup, setSetup] = useState<SetupInterface>({});
    const [loading, setLoading] = useState(true);
    UseSecondLayout();
    useEffect(() => {
        if (loading) {
            load().then(async () => {});
        }
    }, [loading])

    const load = async () => {
        try {
            await SetupApi.detail().then(async (response: ResponseInterface | any) => {
                if (response?.status === config.STATUS_CODE.OK) {
                    setSetup(
                        response?.data?.data || {}
                    )
                }
                await setLoading(false);
            })

        } catch (err) {
        }
        if (loading) {
            await setLoading(false);
        }
    }
    return (
        <React.Fragment>
            <div className="col-md-10 relative">
                <QLoading loading={loading} />
                <div className="row ">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <div className="page-title-name">Stripe business profile</div>
                            </div>
                        </div>
                        <div className="col-lg-12 ">
                            <div className="business-detail bg-orange-bland-zp bdr-20 pd-25">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h2 className="fw-bold fs-24 mb-2">{setup.stripe_business_name?? 'n/a'}</h2>
                                    </div>
                                    <div className="col-md-4 text-end">

                                    </div>
                                </div>
                                <p className="mb-1"><strong className={'text-danger'}>Account ID:</strong> {setup.stripe_user_id?? 'n/a'}</p>
                                <p className="mb-1"><strong className={'text-danger'}>Address:</strong> {setup.stripe_business_support_address?? 'n/a'}</p>
                                <div className="row">
                                    <div className="col-md-4 mb-2"><b className={'text-danger'}>Email:</b> {setup.stripe_business_support_email ?? 'n/a'}</div>
                                    <div className="col-md-4 mb-2"><b className={'text-danger'}>Url:</b> {setup.stripe_business_support_url?? 'n/a'}</div>
                                    <div className="col-md-4 mb-2"><b className={'text-danger'}>Phone:</b> {setup.stripe_business_support_phone?? 'n/a'}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default React.memo(Stripe);
