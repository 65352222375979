import React, {useState} from "react";
import Logo from "../../assets/images/logo/img.png";
import Ensign from "../../assets/images/ams/ensign.png"
import {RouteDefine} from "../../routes/RouteDefine";
import {Link, matchPath, useHistory, useLocation} from "react-router-dom";
import {getLoggedinUser, setAuthorization} from "../../common/api/base";
import {config} from "../../config";
import QFullScreenDropdown from "../../common/components/Genneral/QFullScreenDropdown";
import {adminRoutes} from "../../routes/adminRoute";
import {RouteInterface} from "../../interfaces/RouteInterface";
import {useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {GeneralConstant} from "../../common/constant/GeneralConstant";
import {QHelpers} from "../../common/helpers";
import QModal from "../../common/components/QModal";
import BodyAccountComponent from "../../features/private/Business/components/ChangePassword";
import { AccountInterface } from "../../interfaces/private/AccountInterface";
import {useTranslation} from "react-i18next";

interface Props {

}

const Header: React.FC<Props> = ({}) => {
    const history = useHistory();
    const {t} = useTranslation();
    const root = useSelector((state: RootState) => state.rootReducer)
    const [updateNewClick, setUpdateNewClick] = useState(false);

    const [isClickProfile, setIsClickProfile] = useState(false);
    let userLogged = getLoggedinUser();
    const [loading, setLoading] = useState(true);

    const location = useLocation();
    const [account, setAccount] = useState<AccountInterface>({
        current_password: undefined,
        new_password: undefined,
        new_password_confirm: undefined
    });
    const renderSwitchRole = () => {
        let role: string = QHelpers.string.decrypt(localStorage.getItem(config.LOCAL_STORE.ROLE))

        if(role !== GeneralConstant.user.role.super_admin){
            return null;
        }
        let matchIsAdmin = adminRoutes.find((route: RouteInterface) => {
            return matchPath(location.pathname, {
                path: route.path,
                exact: true,
                strict: false
            });
        })

        if(role === GeneralConstant.user.role.super_admin && matchIsAdmin){
            return null;
        }

        return (
            <a className="dropdown-item cursor-pointer" onClick={ async (event: any) => {
                if (matchIsAdmin){
                    history.push(`${RouteDefine.order}`);
                }else{
                    QHelpers.store.sessionStorageSetItem(
                        config.LOCAL_STORE.ACCOUNT_SWITCH,
                        ""
                    )

                    history.push(`${RouteDefine.admin}`);
                }
            }}>
                <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"/> <span
                className="align-middle" data-key="t-logout">{ t("common.header.switchAdmin") }</span>
            </a>
        )
    }

    return (
        <React.Fragment>
            <QModal
                open={updateNewClick}
                setOpen={setUpdateNewClick}
                headerContent={t("common.header.accountInfo")}
                bodyContent={(
                    <BodyAccountComponent
                        model={account}
                        setOpen={setUpdateNewClick}
                        setAccount={setAccount}
                        setLoadingFunc={setLoading}
                    />
                )}
            />
            <header id="page-topbar">
                <div className="bg-header"/>
                <div className="container-fluid">
                    <div className="layout-width">
                        <div className="navbar-header">
                            <div className="d-flex">
                                {/* LOGO */}
                                <a href="#"><img src={Logo} width={100} alt=""/></a>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="dropdown ms-sm-3 header-item topbar-user">
                                    <button type="button" className="btn btn-icon btn-ghost-secondary rounded-circle"
                                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img id="header-lang-img" src={Ensign} alt="Header Language" className="rounded" height={'16'}/>
                                    </button>
                                   <QFullScreenDropdown />
                                    <button
                                        onClick={() => {
                                            setIsClickProfile(!isClickProfile)
                                        }}
                                        type="button"
                                        className={`btn btn-icon btn-topbar btn-ghost-secondary rounded-circle ${ isClickProfile ? 'show' : '' }`}
                                        id="page-header-user-dropdown"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <i className="las la-user fs-22 cl-icon-hd"/>
                                    </button>
                                    <div className={`dropdown-menu dropdown-menu-end ${ isClickProfile ? 'show active-profile' : '' }`}>
                                        {/* item*/}
                                        <h6 className="dropdown-header">{t("common.header.welcome")} {userLogged?.user?.name}!</h6>
                                        { renderSwitchRole() }
                                      {/*  <a className="dropdown-item cursor-pointer" onClick={ async (event: any) => {
                                            history.push(`${RouteDefine.document}`);
                                        }}>
                                            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"/> <span
                                            className="align-middle" data-key="t-document">{t("common.header.document")}</span>
                                        </a>*/}
                                        <a className="dropdown-item cursor-pointer"  onClick={() => {
                                        setUpdateNewClick(true)}}>
                                            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"/> <span
                                            className="align-middle" data-key="t-document">{t("common.header.changePassword")}</span>
                                        </a>
                                        <a className="dropdown-item cursor-pointer" onClick={ async (event: any) => {
                                            await QHelpers.store.localStorageSetItem(config.LOCAL_STORE.ACCOUNT, JSON.stringify(null))
                                            await QHelpers.store.sessionStorageSetItem(config.LOCAL_STORE.ACCOUNT_SWITCH, "")
                                            await history.push(`${RouteDefine.login}`);
                                        }}>
                                            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"/> <span
                                            className="align-middle" data-key="t-logout">{t("common.header.logout")}</span>
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    )
}

export default React.memo(Header);
