import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ToastInterface} from "../../../interfaces/ToastInterface";
import {useDispatch} from "react-redux";
import {setToast} from "../../../app/rootReducer";

interface Props {
    prop: ToastInterface
}

const QToast: React.FC<Props> = ({prop}) => {
    const dispatch = useDispatch();

    const showNotify = () => {

        setTimeout(() => {
            dispatch(setToast(""))
        }, (prop.autoClose + 2000))

        toast(prop.message, {
            type: prop.type,
        });
        return null;

    }

    return (
        <React.Fragment>
            { showNotify() }
            <ToastContainer
                autoClose={prop.autoClose}
                position={prop.position}
                closeOnClick={prop.closeOnClick}
            />
        </React.Fragment>
    )
}

export default QToast;