import {config} from "../../config";
import {setAuthorization} from "../../common/api/base";
import {matchPath, Redirect, useLocation} from "react-router-dom";
import {RouteDefine} from "../../routes/RouteDefine";
import React from "react";
import {QHelpers} from "../../common/helpers";
import {GeneralConstant} from "../../common/constant/GeneralConstant";
import {adminRoutes} from "../../routes/adminRoute";
import {RouteInterface} from "../../interfaces/RouteInterface";

const AuthProtected = (props: any) => {

    const location = useLocation();

    const token: string|any = QHelpers.store.getAccessToken();
    setAuthorization(token);

    const role = QHelpers.string.decrypt(
        QHelpers.store.localStorageGetItem(config.LOCAL_STORE.ROLE)
    );

    let matchIsAdmin = adminRoutes.find((route: RouteInterface) => {
        return matchPath(location.pathname, {
            path: route.path,
            exact: true,
            strict: false
        });
    })

    if(
        role === GeneralConstant.user.role.super_admin
        && !QHelpers.store.sessionStorageGetItem(config.LOCAL_STORE.ACCOUNT_SWITCH)
        && !matchIsAdmin
    ){
        return (
            <Redirect to={RouteDefine.admin} />
        );
    }

    if (!token) {
        return (
            <Redirect to={{ pathname: RouteDefine.login, state: { from: props.location } }} />
        );
    }
    return <>{props.children}</>;
};

export default AuthProtected
