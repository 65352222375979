import {TableHeaderInterface} from "../../../../../interfaces/TableHeaderInterface";

const headers = (t : any) => {
    return [
        {
            label: "",
            className: "w-20p",
            checkbox: true,
        },
        // {
        //     label: t("log.id"),
        //     sort: 'id',
        //     className: "w-20p"
        // },
        {
            label: 'Stripe payout ID',
            className: "w-20p"
        },
        {
            label: t("log.status"),
            className: "w-20p"
        },
        {
            label: t("log.message"),
            // className: "w-20p"
        },

        {
            label: t("log.created_at"),
            sort: 'created_at',
            className: "w-10p"
        },
        {
            label: "",
            className: "w-5p",
        },
    ]
}

export const OrderConfig = {
    headers,
}
