import React, {useEffect, useState} from "react";
import QTextBox from "../../../common/components/QTextBox";
import { useFormik } from "formik";
import * as Yup from "yup";
import QLoading from "../../../common/components/QLoading";
import QButton from "../../../common/components/QButton";
import AuthApi from "../../../common/api/functions/Auth";
import {ResponseInterface} from "../../../interfaces/ResponseInterface";
import {config} from "../../../config";
import {useHistory, useLocation} from "react-router-dom";
import {RouteDefine} from "../../../routes/RouteDefine";
import {useDispatch} from "react-redux";
import {setToast, setUserRole} from "../../../app/rootReducer";
import {QHelpers} from "../../../common/helpers";
import QModal from "../../../common/components/QModal";
import {GeneralConstant} from "../../../common/constant/GeneralConstant";
import {useTranslation} from "react-i18next";

export function Login() {
    const history = useHistory();
    const { pathname } = useLocation();
    const { t, i18n } = useTranslation();

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    let objectParams = QHelpers.object.convertParamUrlToObject(window.location.search);

    useEffect(() => {
        try {
            let session: any = localStorage.getItem(
                config.LOCAL_STORE.ACCOUNT,
            )

            session = JSON.parse(session);
            if(session?.access_token) {
                history.push(RouteDefine.order);
            }

        }catch (err){}
    }, [])

    useEffect(()=>{
        if(objectParams.verify == 1){
            setOpen(true);
        }
    },[objectParams.verify])

    const formik = useFormik({
        initialValues: {
            agreement_no: '',
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            agreement_no: Yup.string().required('Please Enter Agreement no.'),
            email: Yup.string().required(t("auth.login.enter_email")),
            password: Yup.string().required(t("auth.login.enter_password")),
        }),
        onSubmit: async (values) => {
            await setLoading(true);
            await AuthApi.signIn(values).then(async (response:ResponseInterface|any)=>{

                if (response?.status === config.STATUS_CODE.OK){
                    await dispatch(setToast({
                        message: t("auth.login.login_success"),
                        type: config.TOAST_TYPE.SUCCESS,
                    }))

                    await localStorage.setItem(
                        config.LOCAL_STORE.ACCOUNT,
                        JSON.stringify(response.data.data),
                    )

                    await localStorage.setItem(
                        config.LOCAL_STORE.ROLE,
                        QHelpers.string.encrypt(
                            response?.data?.data?.user?.is_admin === 1
                                ? GeneralConstant.user.role.super_admin
                                : GeneralConstant.user.role.user
                        )
                    )

                    if(response?.data?.data?.user?.is_admin === 1){
                        await dispatch(setUserRole(GeneralConstant.user.role.super_admin));
                        return history.push(RouteDefine.admin);
                    }else if(response?.data?.data?.user?.is_logged === 0){
                        await dispatch(setUserRole(GeneralConstant.user.role.user));
                        return history.push(RouteDefine.setup,response.data.data);
                    }else{
                        await dispatch(setUserRole(GeneralConstant.user.role.user));
                        return history.push(RouteDefine.invoice);
                    }
                }

            },(response:ResponseInterface|any)=>{

                if(typeof response.data?.message == "string"){
                    dispatch(setToast({
                        message: response.data?.message,
                        type: config.TOAST_TYPE.ERROR,
                    }))
                }
            });
            await setLoading(false);
        }
    })

    return (
        <div>
            <div className="mt-4 position-relative">
                <QLoading loading={loading} />
                <QModal
                    open={open}
                    setOpen={() => {
                        setOpen(false)
                    }}
                    loading={loading}
                    headerContent={t("auth.register.verify_account")}
                    bodyContent={(
                        <React.Fragment>
                            <div>{ t("auth.register.verify_success") }</div>
                        </React.Fragment>
                    )}
                />
                <form action="order.html" method="">
                    <h2 className="mb-4 mt-3 fs-32 cl-orange-zp fw-400"> {t("auth.login.sign_in")}</h2>
                    <QTextBox
                        label={'E-conomic Agreement no.'}
                        placeholder={'E-conomic agreement no.'}
                        value={formik.values.agreement_no}
                        className={'form-control-zp bg-transparent bd-bottom'}
                        onChange={(value: string) => {
                            formik.setFieldValue('agreement_no', value)
                        }}
                        error={formik.touched?.agreement_no && formik.errors?.agreement_no}
                    />
                    <QTextBox
                        label={'Email'}
                        placeholder={'Enter username'}
                        value={formik.values.email}
                        className={'form-control-zp bg-transparent bd-bottom'}
                        onChange={(value: string) => {
                            formik.setFieldValue('email', value)
                        }}
                        error={formik.touched?.email && formik.errors?.email}
                    />

                    <QTextBox
                        type={'password'}
                        label={'Password'}
                        placeholder={'Enter password'}
                        value={formik.values.password}
                        className={'form-control-zp bg-transparent bd-bottom'}
                        onChange={(value: string) => {
                            formik.setFieldValue('password', value)
                        }}
                        error={formik.touched?.password && formik.errors?.password}
                    />

                    <div className="mt-4">
                        <QButton
                            content={t("auth.login.sign_in")}
                            type={'success'}
                            width={'w-100'}
                            size={''}
                            className={'btn bg-orange-zp w-100 bdr-10 fs-16'}
                            onClick={() => {
                                formik.handleSubmit()
                            }}
                        />
                    </div>
                    <div className="mt-4">
                        <br/>
                        <br/>
                        <p><a className="cl-orange-zp fw-bold" href={''} onClick={()=>{history.push(RouteDefine.forgotPassword)}}>{t("auth.login.forgot_pass")}</a></p>
                        {/*<p><span>{t("auth.login.dont_have_account")} </span>*/}
                        {/*    <a className="cl-orange-zp fw-bold" href={"#"} onClick={()=>{history.push(RouteDefine.register)}}>{t("auth.login.sign_up")}</a>*/}
                        {/*</p>*/}
                    </div>

                </form>
            </div>
        </div>
    )
}
