import React, { useEffect, useState } from "react";
import QTextBox from "../../../../common/components/QTextBox";
import { ResponseInterface } from "../../../../interfaces/ResponseInterface";
import { config } from "../../../../config";
import { Col, Row } from "reactstrap";
import { Formik } from "formik";
import QButton from "../../../../common/components/QButton";
import { useDispatch } from "react-redux";
import {setLoading, setToast} from "../../../../app/rootReducer";
import SetupApi from "../../../../common/api/functions/Setup";
import { SetupInterface } from "../../../../interfaces/private/SetupInterface";
import { useHistory } from "react-router-dom";
import { QHelpers } from "../../../../common/helpers";
import { RouteDefine } from "../../../../routes/RouteDefine";
import { useTranslation } from "react-i18next";
import QSelect from "../../../../common/components/QSelect";
import { SelectOptionInterface } from "../../../../interfaces/SelectOptionInterface";
import { log } from "console";
import {LayoutInterface, PaymentTermInterface} from "../../../../interfaces/economic/CommonInterface";

interface Props {
    model: SetupInterface,
    setOpen: Function,
    setLoadingFunc: Function,
    setSetup: Function,
    setup: SetupInterface,
    allDataSetup: any,
}
interface Layout{
    layoutNumber: number,
    name: string
}
const BodyInvoiceSetupComponent: React.FC<Props> = ({allDataSetup, model, setOpen, setLoadingFunc, setSetup,setup }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);

    const {layouts,paymentTerms,bookedWiths,otherRef} = allDataSetup;
    useEffect(() => {
        if (loading) {
            load().then(async () => {setLoading(false)})
        }
    }, [loading])

    const load = async () => {
        try {
            await SetupApi.detail().then(async (response: ResponseInterface | any) => {
                if (response?.status === config.STATUS_CODE.OK) {
                    setSetup(
                        response?.data?.data || {}
                    )
                }
            })

        } catch (err) {
        }
        if (loading) {
            await setLoading(false);
        }
    }
    return (
        <React.Fragment>
            <Formik
                initialValues={{ ...setup }}
                onSubmit={(values: any) => {
                    SetupApi.update(values).then(async (response: ResponseInterface | any) => {
                        if (response.status === config.STATUS_CODE.OK) {
                            setSetup(
                                response?.data?.data || {}
                            )
                    /*        let objectParams = QHelpers.object.convertParamUrlToObject(window.location.search)
                            if (objectParams?.token) {
                                history.push(RouteDefine.economic)
                            }*/

                            await setOpen(false);
                            await setLoadingFunc(true);
                            await dispatch(setToast({
                                message: "Update success",
                                type: config.TOAST_TYPE.SUCCESS
                            }))
                        }
                    })
                }}
            >
                {formik => {
                    console.log(formik.values);
                    return (
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <QSelect
                                        label={'Layout'}
                                        value={parseInt(formik.values.economic_invoice_layout_number)}
                                        onChange={(option:SelectOptionInterface) => {
                                            formik.setFieldValue('economic_invoice_layout_number',option.value);
                                        }}
                                        options={layouts.map((a:LayoutInterface)=>({label:a.name,value:a.layoutNumber}))}
                                        error={formik.errors?.economic_invoice_layout_number}
                                    />
                                    <QSelect
                                        label={'Payment Term'}
                                        value={parseInt(formik.values.economic_invoice_payment_terms_number)}
                                        onChange={(option:SelectOptionInterface) => {
                                            formik.setFieldValue('economic_invoice_payment_terms_number',option.value);
                                        }}
                                        options={paymentTerms.map((a:PaymentTermInterface)=>({label:a.name,value:a.paymentTermsNumber}))}
                                        error={formik.errors?.economic_invoice_payment_terms_number}
                                    />

                                    <QSelect
                                        label={'Booked with'}
                                        value={formik.values.economic_invoice_booked_with}
                                        onChange={(option:SelectOptionInterface) => {
                                            formik.setFieldValue('economic_invoice_booked_with',option.value);
                                        }}
                                        options={Object.entries(bookedWiths).map(([key, value]) => ({label:value,value:key}))}
                                        error={formik.errors?.economic_invoice_booked_with}
                                    />

                                    <QSelect
                                        label={'Other reference'}
                                        value={formik.values.economic_invoice_other_ref}
                                        onChange={(option:SelectOptionInterface) => {
                                            formik.setFieldValue('economic_invoice_other_ref',option.value);
                                        }}
                                        options={Object.entries(otherRef).map(([key, value]) => ({label:value,value:key}))}
                                        error={formik.errors?.economic_invoice_other_ref}
                                    />
                                    <QTextBox
                                        label={'Product discount'}
                                        value={formik.values.economic_invoice_product_discount_number}
                                        placeholder={'Fill in product number using for discount'}
                                        onChange={(event: any) => {
                                            formik.setFieldValue('economic_invoice_product_discount_number', event);
                                        }}
                                        error={formik.errors?.economic_invoice_product_discount_number}

                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className={'d-flex justify-content-end gap-2'}>
                                        <QButton
                                            type={'default'}
                                            onClick={() => {
                                                setOpen(false)
                                            }}
                                            content={t("common.button.cancel")}
                                        />

                                        <QButton
                                            type={'primary'}
                                            onClick={() => {
                                                formik.handleSubmit()
                                            }}
                                            content={t("common.button.submit")}
                                        />

                                    </div>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )
                }}
            </Formik>
        </React.Fragment>
    )
}

export default BodyInvoiceSetupComponent
