export interface IntegrateInterface{
    "id": number,
    "host": string|any,
    "key": string|any,
    "secret": string|any,
    "cms_type": number|any,
    "order_last_update": string|any,
    "created_at": string|any,
    "updated_at": string|any,
    "user_id": number|any,
    "cms_url": string|any,
    "token": string|any,
    "status": number|any
}

export const structIntegration : IntegrateInterface = {
    id: 0,
    host: "",
    key: "",
    secret: "",
    cms_type: "",
    order_last_update: "",
    cms_url: "",
    created_at: "",
    updated_at: "",
    user_id: "",
    token: "",
    status: 1,
}
