import React from "react";

interface Props {

}

const LeftSideBar: React.FC<Props> = () => {
    // let {Url,SetUrl} = useContext(UrlContext);
    // SetUrl(stateUrl);
    return (
        <div className="col-md-auto">
            {/*<div className="siderbar-nav-left mt-3 w-180">*/}
            {/*    <h3 className="fs-16 cl-ams fw-bold mb-4">Management</h3>*/}
            {/*    <ul className="pl-0">*/}
            {/*        <li className={`${stateUrl === RouteDefine.order ? 'active' : ''}`}*/}
            {/*            onClick={() => {*/}
            {/*                setStateUrl(RouteDefine.order)*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            <Link to={RouteDefine.order}>Orders</Link>*/}
            {/*        </li>*/}
            {/*        {*/}
            {/*            stateShowMore ? (*/}
            {/*                <>*/}
            {/*                    <li className={`${stateUrl === RouteDefine.product ? 'active' : ''}`}*/}
            {/*                        onClick={() => {*/}
            {/*                            setStateUrl(RouteDefine.product)*/}
            {/*                        }}*/}
            {/*                    >*/}
            {/*                        <Link to={RouteDefine.product}>Products</Link>*/}
            {/*                    </li>*/}
            {/*                    <li className={`${stateUrl === RouteDefine.customer ? 'active' : ''}`}*/}
            {/*                        onClick={() => {*/}
            {/*                            setStateUrl(RouteDefine.customer)*/}
            {/*                        }}*/}
            {/*                    >*/}
            {/*                        <Link to={RouteDefine.customer}>Customers</Link>*/}
            {/*                    </li>*/}
            {/*                </>*/}
            {/*            ) : (*/}
            {/*                <i className="fa fa-plus q-color-active ml-3 cursor-pointer" aria-hidden="true" onClick={() => { setStateShowMore(true) }} />*/}
            {/*            )*/}
            {/*        }*/}
            {/*    </ul>*/}
            {/*</div>*/}
        </div>
    )
}

export default LeftSideBar;
