import React, { useEffect, useState } from "react";
import QTextBox from "../../../../common/components/QTextBox";
import { ResponseInterface } from "../../../../interfaces/ResponseInterface";
import { config } from "../../../../config";
import { Col, Row } from "reactstrap";
import { Formik } from "formik";
import QButton from "../../../../common/components/QButton";
import { useDispatch } from "react-redux";
import {setLoading, setToast} from "../../../../app/rootReducer";
import SetupApi from "../../../../common/api/functions/Setup";
import { SetupInterface } from "../../../../interfaces/private/SetupInterface";
import { useHistory } from "react-router-dom";
import { QHelpers } from "../../../../common/helpers";
import { RouteDefine } from "../../../../routes/RouteDefine";
import { useTranslation } from "react-i18next";
import QSelect from "../../../../common/components/QSelect";
import { SelectOptionInterface } from "../../../../interfaces/SelectOptionInterface";
import { log } from "console";
import {
    AccountInterface,
    JournalInterface,
    LayoutInterface,
    PaymentTermInterface
} from "../../../../interfaces/economic/CommonInterface";

interface Props {
    model: SetupInterface,
    setOpen: Function,
    setLoadingFunc: Function,
    setSetup: Function,
    setup: SetupInterface,
    allDataSetup: any,
}
interface Layout{
    layoutNumber: number,
    name: string
}
const BodyPaymentSetupComponent: React.FC<Props> = ({allDataSetup, model, setOpen, setLoadingFunc, setSetup,setup }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);

    const {cashbooks,accounts} = allDataSetup;
    useEffect(() => {
        if (loading) {
            load().then(async () => {setLoading(false)})
        }
    }, [loading])

    const load = async () => {
        try {
            await SetupApi.detail().then(async (response: ResponseInterface | any) => {
                if (response?.status === config.STATUS_CODE.OK) {
                    setSetup(
                        response?.data?.data || {}
                    )
                }
            })

        } catch (err) {
        }
        if (loading) {
            await setLoading(false);
        }
    }
    return (
        <React.Fragment>
            <Formik
                initialValues={{ ...setup }}
                onSubmit={(values: any) => {
                    SetupApi.update(values).then(async (response: ResponseInterface | any) => {
                        if (response.status === config.STATUS_CODE.OK) {
                            setSetup(
                                response?.data?.data || {}
                            )
                    /*        let objectParams = QHelpers.object.convertParamUrlToObject(window.location.search)
                            if (objectParams?.token) {
                                history.push(RouteDefine.economic)
                            }*/

                            await setOpen(false);
                            await setLoadingFunc(true);
                            await dispatch(setToast({
                                message: "Update success",
                                type: config.TOAST_TYPE.SUCCESS
                            }))
                        }
                    })
                }}
            >
                {formik => {
                    console.log(formik.values);
                    return (
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <QSelect
                                        label={'Cashbook'}
                                        value={parseInt(formik.values.economic_payment_cashbook_number)}
                                        onChange={(option:SelectOptionInterface) => {
                                            formik.setFieldValue('economic_payment_cashbook_number',option.value);
                                        }}
                                        options={cashbooks.map((a:JournalInterface)=>({label:a.name,value:a.journalNumber}))}
                                        error={formik.errors?.economic_payment_cashbook_number}
                                    />
                                    <QSelect
                                        label={'Bank account'}
                                        value={parseInt(formik.values.economic_payment_bank_account_number)}
                                        onChange={(option:SelectOptionInterface) => {
                                            formik.setFieldValue('economic_payment_bank_account_number',option.value);
                                        }}
                                        options={accounts.map((a:AccountInterface)=>({label:a.accountNumber + ' - ' + a.name,value:a.accountNumber}))}
                                        error={formik.errors?.economic_payment_bank_account_number}
                                    />
                                    <QSelect
                                        label={'Fee account'}
                                        value={parseInt(formik.values.economic_payment_fee_account_number)}
                                        onChange={(option:SelectOptionInterface) => {
                                            formik.setFieldValue('economic_payment_fee_account_number',option.value);
                                        }}
                                        options={accounts.map((a:AccountInterface)=>({label:a.accountNumber + ' - ' + a.name,value:a.accountNumber}))}
                                        error={formik.errors?.economic_payment_fee_account_number}
                                    />
                                    <QSelect
                                        label={'Difference currency account'}
                                        value={parseInt(formik.values.economic_payment_difference_currency_account_number)}
                                        onChange={(option:SelectOptionInterface) => {
                                            formik.setFieldValue('economic_payment_difference_currency_account_number',option.value);
                                        }}
                                        options={accounts.map((a:AccountInterface)=>({label:a.accountNumber + ' - ' + a.name,value:a.accountNumber}))}
                                        error={formik.errors?.economic_payment_difference_currency_account_number}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className={'d-flex justify-content-end gap-2'}>
                                        <QButton
                                            type={'default'}
                                            onClick={() => {
                                                setOpen(false)
                                            }}
                                            content={t("common.button.cancel")}
                                        />

                                        <QButton
                                            type={'primary'}
                                            onClick={() => {
                                                formik.handleSubmit()
                                            }}
                                            content={t("common.button.submit")}
                                        />

                                    </div>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )
                }}
            </Formik>
        </React.Fragment>
    )
}

export default BodyPaymentSetupComponent
