import { RouteInterface } from "../interfaces/RouteInterface";
import {Login} from "../features/public/Login/Login";
import {RouteDefine} from "./RouteDefine";
import Setup from "../features/public/Setup";
import ResetPassword from "../features/public/ResetPassword/ResetPassword";
import ForgotPassword from "../features/public/ResetPassword/ForgotPassword";
import { Register } from "../features/public/Register/Register";
import { Config } from "../features/public/Config/Config";
import {AdminLogin} from "../features/public/AdminLogin/AdminLogin";

export const authRoutes : Array<RouteInterface> = [
    { path: RouteDefine.login, Component: Login },
    { path: RouteDefine.adminLogin, Component: AdminLogin },
    {path: RouteDefine.resetPassword, Component: ResetPassword},
    {path: RouteDefine.forgotPassword, Component: ForgotPassword},
    // {path: '/register', Component: Register},
    // {path: '/config', Component: Config},

]
