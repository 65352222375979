import {Col, Row} from "reactstrap";
import React from "react";

interface Props {
}
const BodyModalSubscriptionTermsComponent: React.FC<Props> = ({  }) => {
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <ul>
                        <li>Test for 14 days free</li>
                        <li>Subscriptions can end with one month notice before month end</li>
                        <li>Subscriptions price and setup fee on <a target={'_blank'} href={'https://2app.dk/app-detail/stripe-til-e-conomic.html'}>https://2app.dk/app-detail/stripe-til-e-conomic.html</a></li>
                    </ul>

                </Col>
            </Row>

        </React.Fragment>
    )
}

export default BodyModalSubscriptionTermsComponent
