import React from "react";
import Header from "../components/Header";

function AdminTemplate ({children} : HTMLElement|any) {
    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <div className="vertical-overlay"/>
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AdminTemplate;
