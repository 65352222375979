import React from 'react';

const CircularProgress = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
        }}>
            <div style={{
                width: '40px',
                height: '40px',
                border: '4px solid rgba(0, 0, 0, 0.1)',
                borderTop: '4px solid #EA6C00',
                borderRadius: '50%',
                animation: 'spin 1s linear infinite',
            }} />
        </div>
    );
};

export default CircularProgress;
