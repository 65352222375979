import React, {useState} from 'react';

import "./assets/css/sweetalert2.min.css";
import "./assets/css/bootstrap-rtl.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/icons.min.css";
import "./assets/css/app-rtl.min.css";
import "./assets/css/app.min.css";
import "./assets/css/zapp.css";
import "./App.css";
import Routes from "./routes";
import {useSelector} from "react-redux";
import {RootState} from "./app/store";
import {LayoutContextProvider} from "./context/LayoutContext";
import QToast from "./common/components/QToast";

function App() {

    const root = useSelector((state: RootState) => state.rootReducer)
    return (
        <LayoutContextProvider>
            {
                root.toast.message ? <QToast prop={root.toast} /> : null
            }

            <Routes/>
        </LayoutContextProvider>
    );
}

export default App;
