import {APIClient} from "../base";
import {PaginationInterface} from "../../../interfaces/PaginationInterface";
import {IntegrateInterface} from "../../../interfaces/private/IntegrateInterface";

const IntegrateApi = {
    list: async (pagination: PaginationInterface) => await APIClient.get('/integrate', pagination),
    detail: async (id: number) => await APIClient.get('/integrate/' + id, {}),
    create: async (model: IntegrateInterface) => await APIClient.create('/integrate', model),
    update: async (id: number, model: IntegrateInterface) => await APIClient.update('/integrate/' + id, model),
    delete: async (id: number|string|any) => await APIClient.delete('/integrate/' + id, {}),
    cmsType: async () => await APIClient.get('/integrate/cms-type', {}),


    syncInvoice:async (params?:any) => await APIClient.post('/integrate/invoice', params),
    syncPayment:async (params?:any) => await APIClient.post('/integrate/payment', params),
    syncPayout:async (params?:any) => await APIClient.post('/integrate/payout', params),
    syncSubscription:async () => await APIClient.post('/integrate/subscription', {}),
}

export default IntegrateApi;
