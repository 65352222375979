import React, {useCallback, useEffect, useState} from "react";
import QTextBox from "../../../../common/components/QTextBox";
import QSelect from "../../../../common/components/QSelect";
import UserApi from "../../../../common/api/functions/User";
import SetupApi from "../../../../common/api/functions/Setup";
import {ResponseInterface} from "../../../../interfaces/ResponseInterface";
import {LayoutInterface, PaymentTermInterface, ProductInterface} from "../../../../interfaces/economic/CommonInterface";
import {SelectOptionInterface} from "../../../../interfaces/SelectOptionInterface";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../app/store";
import {setLoading} from "../../../../app/rootReducer";
import {Progress, Spinner} from "reactstrap";
import QSelectSearch from "../../../../common/components/QSelectSearch";
import {handleInputChange} from "react-select/dist/declarations/src/utils";
import { debounce } from "lodash"

interface Props {
    formik: any
}

const Invoice: React.FC<Props> = ({formik}) => {
    const [layouts,setLayouts] = useState<LayoutInterface[]>([]);
    const [paymentTerms,setPaymentTerms] = useState<PaymentTermInterface[]>([]);
    const [bookedWiths,setBookedWiths] = useState({});
    const [otherRef,setOtherRef] = useState({});
    const [products, setProducts] = useState<ProductInterface[]>([]);
    const [loadingProduct, setLoadingProduct] = useState(true);
    // const [layouts,setLayouts] = useState<LayoutInterface[]>([]);
    const {loading} = useSelector((state:RootState) => state.rootReducer);
    const dispatch = useDispatch();



    useEffect(()=>{

        if(loading){
            SetupApi.getDataSetupInvoice().then(async (response: ResponseInterface | any)=>{
                let {layouts,paymentTerms,bookedWiths,otherRef} = response?.data?.data;
                setLayouts(layouts);
                setPaymentTerms(paymentTerms);
                setBookedWiths(bookedWiths);
                setOtherRef(otherRef);
                dispatch(setLoading(false));
            })

        }
    },[loading]);


    const searchProduct = useCallback(debounce((inputValue:string) => {
        console.log(inputValue);
        return SetupApi.searchProduct(inputValue).then(async (response: ResponseInterface | any)=>{
            return response?.data?.data?.map((item:ProductInterface) => ({
                label:item.name,
                value:item.productNumber,
            }));
        });
    },300),[]);


    const promiseOptions = useCallback(debounce((e:string,callback) => {
        const controller = new AbortController();
        if(e.length > 1){
            SetupApi.searchProduct(e).then(async (response: ResponseInterface | any)=> {
                return response?.data?.data?.map((item: ProductInterface) => ({
                    label: item.productNumber + ' - ' + item.name,
                    value: item.productNumber,
                }))
            }).then(results => {
                callback(results);
            });
        }else{
            callback(null);
        }

        return () => controller.abort();
    },500),[]);


    // console.log(formik.errors);
    return(
        <React.Fragment>

            <QSelect
                label={'Layout'}
                value={formik.values.economic_invoice_layout_number}
                onChange={(option:SelectOptionInterface) => {
                    formik.setFieldValue('economic_invoice_layout_number',option.value);
                }}
                options={layouts.map((a:LayoutInterface)=>({label:a.name,value:a.layoutNumber}))}
                error={formik.errors?.economic_invoice_layout_number}
            />
            <QSelect
                label={'Payment Term'}
                value={formik.values.economic_invoice_payment_terms_number}
                onChange={(option:SelectOptionInterface) => {
                    formik.setFieldValue('economic_invoice_payment_terms_number',option.value);
                }}
                options={paymentTerms.map((a:PaymentTermInterface)=>({label:a.name,value:a.paymentTermsNumber}))}
                error={formik.errors?.economic_invoice_payment_terms_number}
            />

            <QSelect
                label={'Booked with'}
                value={formik.values.economic_invoice_booked_with}
                onChange={(option:SelectOptionInterface) => {
                    formik.setFieldValue('economic_invoice_booked_with',option.value);
                }}
                options={Object.entries(bookedWiths).map(([key, value]) => ({label:value,value:key}))}
                error={formik.errors?.economic_invoice_booked_with}
            />

            <QSelect
                label={'Other reference'}
                value={formik.values.economic_invoice_other_ref}
                onChange={(option:SelectOptionInterface) => {
                    formik.setFieldValue('economic_invoice_other_ref',option.value);
                }}
                options={Object.entries(otherRef).map(([key, value]) => ({label:value,value:key}))}
                error={formik.errors?.economic_invoice_other_ref}
            />
            <QSelectSearch
                label={'Product discount'}
                value={formik.values.economic_invoice_product_discount_number}
                onChange={(option:SelectOptionInterface) => {
                    formik.setFieldValue('economic_invoice_product_discount_number',option.value);
                }}
                // loading={loadingProduct}
                // options={products.map((a:ProductInterface)=>({label:a.productNumber + ' - ' + a.name,value:a.productNumber}))}
                error={formik.errors?.economic_invoice_product_discount_number}
                placeholder={'Fill in product number using for discount'}
                promiseOptions={promiseOptions}
            />
{/*            <QTextBox
                label={'Product discount'}
                value={formik.values.economic_invoice_product_discount_number}
                placeholder={'Fill in product number using for discount'}
                onChange={(event: any) => {
                    formik.setFieldValue('economic_invoice_product_discount_number', event);
                }}
                error={formik.errors?.economic_invoice_product_discount_number}

            />*/}

        </React.Fragment>
    )
}

export default Invoice;
