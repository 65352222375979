import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import CircularProgress from "./components/CircularProgress";

interface Props {
    loading: boolean;
}

const QLoading: React.FC<Props> = ({ loading }) => {
    const [isVisible, setIsVisible] = useState(loading);

    useEffect(() => {
        if (loading) {
            setIsVisible(true); // Hiển thị khi loading là true
        } else {
            // Ẩn khi loading là false
            const timeout = setTimeout(() => setIsVisible(false), 1000); // Thời gian phải trùng với transition
            return () => clearTimeout(timeout);
        }
    }, [loading]);

    return (
        <React.Fragment>
            {isVisible && (
                <div
                    className={`${styles.qLoadingFadeIn} ${!loading ? styles.qLoadingFadeOut : ''}`}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999,
                    }}
                >
                    <CircularProgress />
                </div>
            )}
        </React.Fragment>
    );
}

export default QLoading;
