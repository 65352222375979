import React, {useEffect, useState} from "react";
import {Col, Progress, Row, Table} from "reactstrap";
import QButton from "../../../../common/components/QButton";
import {t} from "i18next";
import {setLoading} from "../../../../app/rootReducer";
import QLoading from "../../../../common/components/QLoading";
import {LogInterface} from "../../../../interfaces/private/LogInterface";
import moment from "moment/moment";

interface Props {
    open: number | boolean, // open > 0 show
    loading: boolean, // open > 0 show
    setOpen: Function,
    setLoading: Function,
    log: LogInterface,
}

const BodyModalLogDetailComponent: React.FC<Props> = ({log,loading,setLoading,setOpen, open, ...props}) => {
    return (
        <React.Fragment>
            <QLoading loading={loading}/>
            <Row>
                <Table hover>
                    <tbody>
                    <tr>
                        <th scope="row">
                            Stripe Invoice Id
                        </th>
                        <td>
                            {log.ref_id}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            Status
                        </th>
                        <td>
                            {log.status}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            Message
                        </th>
                        <td>
                            {log.message}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            Created at
                        </th>
                        <td>
                            {moment(log.created_at).format('hh:mm DD.MM.YY')}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            Data
                        </th>
                        <td>
                            <pre>{JSON.stringify(log.data,null,2)}</pre>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </Row>

            {/*<Row>
                <Col>
                    <div className={'d-flex justify-content-end gap-2'}>
                        <QButton
                            type={'default'}
                            onClick={() => {
                                setOpen(false)
                            }}
                            content={t("common.button.cancel")}
                        />

                        <QButton
                            type={'primary'}
                            onClick={handleSubmit}
                            content={t("common.button.submit")}
                        />

                    </div>
                </Col>
            </Row>*/}
        </React.Fragment>
    )
}

export default BodyModalLogDetailComponent
