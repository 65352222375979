import React, {useEffect, useState} from "react";
import QTextBox from "../../../../common/components/QTextBox";
import QSelect from "../../../../common/components/QSelect";
import UserApi from "../../../../common/api/functions/User";
import SetupApi from "../../../../common/api/functions/Setup";
import {ResponseInterface} from "../../../../interfaces/ResponseInterface";
import {
    CustomerGroupInterface,
    LayoutInterface,
    PaymentTermInterface, ProductGroupInterface
} from "../../../../interfaces/economic/CommonInterface";
import {SelectOptionInterface} from "../../../../interfaces/SelectOptionInterface";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../app/store";
import {setLoading} from "../../../../app/rootReducer";

interface Props {
    formik: any
}

const Common: React.FC<Props> = ({formik}) => {
    const {loading} = useSelector((state:RootState) => state.rootReducer);
    const dispatch = useDispatch();
    const [customerGroups,setCustomerGroups] = useState<CustomerGroupInterface[]>([]);
    const [productGroups,setProductGroups] = useState<ProductGroupInterface[]>([]);
    const [paymentTerms,setPaymentTerms] = useState<PaymentTermInterface[]>([]);
    // const [layouts,setLayouts] = useState<LayoutInterface[]>([]);

    useEffect(()=>{
        if(loading){
            SetupApi.getDataSetupCommon().then(async (response: ResponseInterface | any)=>{
                let {customerGroups,paymentTerms,productGroups} = response?.data?.data;
                setPaymentTerms(paymentTerms);
                setCustomerGroups(customerGroups);
                setProductGroups(productGroups);
                dispatch(setLoading(false));
            })

        }
    },[loading]);


    // console.log(formik.errors);
    return(
        <React.Fragment>
            <QSelect
                label={'Customer group'}
                value={formik.values.economic_customer_group_number}
                onChange={(option:SelectOptionInterface) => {
                    formik.setFieldValue('economic_customer_group_number',option.value);
                }}
                options={customerGroups.map((a:CustomerGroupInterface)=>({label:a.name,value:a.customerGroupNumber}))}
                error={formik.errors?.economic_customer_group_number}
            />
            <QSelect
                label={'Customer payment term'}
                value={formik.values.economic_customer_payment_terms_number}
                onChange={(option:SelectOptionInterface) => {
                    formik.setFieldValue('economic_customer_payment_terms_number',option.value);
                }}
                options={paymentTerms.map((a:PaymentTermInterface)=>({label:a.name,value:a.paymentTermsNumber}))}
                error={formik.errors?.economic_customer_payment_terms_number}
            />
            <QSelect
                label={'Product group'}
                value={formik.values.economic_product_group_number}
                onChange={(option:SelectOptionInterface) => {
                    formik.setFieldValue('economic_product_group_number',option.value);
                }}
                options={productGroups.map((a:ProductGroupInterface)=>({label:a.name,value:a.productGroupNumber}))}
                error={formik.errors?.economic_product_group_number}
            />
        </React.Fragment>
    )
}

export default Common;
