import React from "react";

interface Props {
    className?: string,
}

const QIconCheck: React.FC<Props> = ({ className }) => {
    return (
        <i className={`fa fa-user-o ${className}`} aria-hidden="true" />
    )
}

export default QIconCheck
