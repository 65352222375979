import React, { useState } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

interface ImageZoomModalProps {
    src: string;
    alt?: string;
    modalSrc: string;
}

const ImageZoomModal: React.FC<ImageZoomModalProps> = ({ src, alt, modalSrc }) => {
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);

    return (
        <div>
            <img
                src={src}
                alt={alt}
                onClick={openModal}
                style={{ cursor: 'pointer' }}
            />
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Image Zoom"
                style={{
                    content: {
                        textAlign: 'center',
                        padding: '0',
                        border: 'none',
                        background: 'transparent',
                    },
                }}
            >
                <img
                    src={modalSrc}
                    alt={alt}
                />
                <button
                    onClick={closeModal}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        background: 'orange', // Màu cam
                        color: 'white',
                        border: 'none',
                        borderRadius: '50%',
                        width: '30px',
                        height: '30px',
                        cursor: 'pointer',
                        fontSize: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    &times;
                </button>
            </Modal>
        </div>
    );
};

export default ImageZoomModal;
